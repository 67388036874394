:root {
  --primary-h: 24;
  --primary-s: 74%;
  --primary-l: 58%;
  --primary-color: var(--primary-h), var(--primary-s), var(--primary-l);
  --secondary-h: 13;
  --secondary-s: 96%;
  --secondary-l: 47%;
  --secondary-color: var(--secondary-h), var(--secondary-s), var(--secondary-l);
  --light-h: 0;
  --light-s: 0%;
  --light-l: 100%;
  --light-color: var(--light-h), var(--light-s), var(--light-l);
  --dark-h: 0;
  --dark-s: 0%;
  --dark-l: 12%;
  --dark-color: var(--dark-h), var(--dark-s), var(--dark-l);
  --success-h: 135;
  --success-s: 60%;
  --success-l: 40%;
  --success-color: var(--success-h), var(--success-s), var(--success-l);
  --danger-h: 355;
  --danger-s: 70%;
  --danger-l: 55%;
  --danger-color: var(--danger-h), var(--danger-s), var(--danger-l);
  --warning-h: 45;
  --warning-s: 100%;
  --warning-l: 50%;
  --warning-color: var(--warning-h), var(--warning-s), var(--warning-l);
  --info-h: 180;
  --info-s: 80%;
  --info-l: 40%;
  --info-color: var(--info-h), var(--info-s), var(--info-l);
}

.light_theme {
  --main-color-one: var(--primary-color);
  --main-color-two: var(--secondary-color);
  --bg-color-one: var(--light-h), var(--light-s), calc(var(--light-l)  - 10%);
  --bg-color-two: var(--light-color);
  --bg-color-reverse: var(--dark-color);
  --inputs-bg-color: var(--light-h), var(--light-s), calc(var(--light-l)  - 7%);
  --t-color-one: var(--dark-color);
  --t-color-two: var(--dark-h), var(--dark-s), calc(var(--dark-l)  + 25%);
  --border-color: var(--light-h), var(--light-s), calc(var(--light-l)  - 4%);
  --card-shadow: 11px 10px 38px #0000001f;
  --drop-shadow: drop-shadow(2px 4px 6px #0003);
  --filter-invert-true: invert(1);
  --filter-invert-false: invert(0);
}

.dark_theme {
  --main-color-one: var(--primary-color);
  --main-color-two: var(--secondary-color);
  --bg-color-one: var(--dark-color);
  --bg-color-two: var(--dark-h), var(--dark-s), calc(var(--dark-l)  + 7%);
  --bg-color-reverse: var(--light-color);
  --inputs-bg-color: var(--dark-h), var(--dark-s), calc(var(--dark-l)  + 4%);
  --t-color-one: var(--light-color);
  --t-color-two: var(--dark-h), var(--dark-s), calc(var(--dark-l)  + 50%);
  --border-color: var(--dark-h), var(--dark-s), calc(var(--dark-l)  + 4%);
  --card-shadow: 11px 10px 38px #00000061;
  --drop-shadow: drop-shadow(2px 4px 6px #0003);
  --filter-invert-true: invert(0);
  --filter-invert-false: invert(1);
}

html[dir="ltr"] body {
  --logo-font-family: "Saira Stencil One", sans-serif;
  --headings-font-family: "Poppins", sans-serif;
  --body-font-family: "Roboto", sans-serif;
}

html[dir="rtl"] body {
  --logo-font-family: "Tajawal", sans-serif;
  --headings-font-family: "Tajawal", sans-serif;
  --body-font-family: "Tajawal", sans-serif;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 20px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes fadeInUpLeftTranslate {
  0% {
    opacity: 0;
    transform: translate(20px, 20px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeInUpRightTranslate {
  0% {
    opacity: 0;
    transform: translate(-20px, 20px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes bigFadeInUp {
  0% {
    opacity: 0;
    margin-top: 40px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    margin-top: -20px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    margin-right: -20px;
  }

  100% {
    opacity: 1;
    margin-right: 0;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    margin-left: -20px;
  }

  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes spinner-left {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-right {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes rotateInLeft {
  0% {
    opacity: 0;
    transform: rotate(-200deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes rotateInRight {
  0% {
    opacity: 0;
    transform: rotate(200deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDown {
  0% {
    max-height: 0;
    overflow: hidden;
  }

  100% {
    max-height: 100vh;
    overflow: unset;
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 hsla(var(--primary-color), 50%);
    box-shadow: 0 0 0 0 hsla(var(--primary-color), 50%);
  }

  100% {
    box-shadow: 0 0 0 10px #0000;
  }
}

@keyframes lightSpeedInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes lightSpeedInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes flash {
  0% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes leftShine {
  100% {
    left: 125%;
  }
}

@keyframes rightShine {
  100% {
    right: 125%;
  }
}

@keyframes floating {
  0% {
    transform: rotate(0deg)translate(-10px)rotate(0deg);
  }

  100% {
    transform: rotate(360deg)translate(-10px)rotate(-360deg);
  }
}

* {
  box-sizing: border-box;
}

::selection {
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
}

html {
  scroll-behavior: smooth;
}

html body {
  text-align: initial;
  overflow-x: hidden;
}

.hidden {
  display: none;
}

.ltr-dir {
  direction: ltr;
  display: inline-block;
}

hr {
  width: 100%;
  margin: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a, button {
  cursor: pointer;
  transition: all .3s ease-in-out;
  outline: 0 !important;
}

button {
  background: none;
  border: none;
  padding: 0;
}

a, a:hover {
  color: inherit;
  text-decoration: none;
}

.btn {
  text-transform: uppercase;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.btn.disabled, .btn:disabled {
  cursor: not-allowed;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit !important;
}

input:invalid, input:required, textarea:invalid, textarea:required {
  -webkit-box-shadow: none;
  box-shadow: none;
}

body .page {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section {
  outline: none;
}

.section:not(.page) {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section .section-title {
  text-align: center;
  margin-bottom: 60px;
}

.section .section-title .subtitle {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin-bottom: .6rem;
  display: flex;
}

.section .section-title .title {
  line-height: 2.6rem;
}

.section .section-title .title:last-child {
  margin: 0;
}

.section .section-title .title:not(:last-child) {
  margin-bottom: 20px;
}

.section .section-title p {
  margin: 0;
}

@media (min-width: 992px) {
  .section .text-box-inline {
    position: relative;
  }

  html[dir="ltr"] .section .text-box-inline {
    padding-left: 40px;
  }

  html[dir="rtl"] .section .text-box-inline {
    padding-right: 40px;
  }
}

.section .text-box-inline > * {
  visibility: hidden;
}

.section .text-box-inline .subtitle {
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .section .text-box-inline .subtitle {
    align-items: center;
    display: flex;
    position: absolute;
    top: 5px;
  }

  html[dir="ltr"] .section .text-box-inline .subtitle {
    transform-origin: 0 0;
    left: 0;
    transform: rotate(270deg)translateX(-100%);
  }

  html[dir="rtl"] .section .text-box-inline .subtitle {
    transform-origin: 100% 0;
    right: 0;
    transform: rotate(-270deg)translateX(100%);
  }

  .section .text-box-inline .subtitle:before {
    content: "";
    height: 2px;
    width: 60px;
    background-color: hsl(var(--t-color-two));
    display: inline-block;
  }

  html[dir="ltr"] .section .text-box-inline .subtitle:before {
    margin-right: 20px;
  }

  html[dir="rtl"] .section .text-box-inline .subtitle:before {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .section .text-box-inline .subtitle:after {
    content: "";
    height: 2px;
    width: 60px;
    background-color: hsl(var(--t-color-two));
    margin-top: 5px;
    margin-bottom: 16px;
    display: block;
  }
}

.section .text-box-inline > :not(.subtitle):not(:last-child) {
  margin-bottom: 30px;
}

.section .text-box-inline p:last-child {
  margin-bottom: 0;
}

.section .text-box-block .subtitle {
  text-transform: uppercase;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.section .text-box-block .subtitle:before {
  content: "";
  height: 2px;
  width: 60px;
  background-color: hsl(var(--t-color-two));
  display: inline-block;
}

html[dir="ltr"] .section .text-box-block .subtitle:before {
  margin-right: 20px;
}

html[dir="rtl"] .section .text-box-block .subtitle:before {
  margin-left: 20px;
}

.section .text-box-block .subtitle a:hover {
  color: hsl(var(--main-color-one));
}

.section .text-box-block .subtitle a:focus {
  outline: none;
}

.enable-focus-style .section .text-box-block .subtitle a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.section .text-box-block > :not(.subtitle):not(:last-child) {
  margin-bottom: 30px;
}

.section .text-box-block p:last-child {
  margin-bottom: 0;
}

#app-inner {
  min-height: 100vh;
  width: 100%;
  background-color: hsl(var(--bg-color-one));
  color: hsl(var(--t-color-two));
  font-family: var(--body-font-family);
  padding-top: 70px;
  transition: background .6s ease-in-out, padding-top .6s ease-in-out;
  overflow-x: hidden;
}

html[dir="ltr"] #app-inner {
  font-size: 1rem;
}

html[dir="rtl"] #app-inner {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

h1, h2, h3, h4, h5, h6, strong, label, .section-title .title {
  color: hsl(var(--t-color-one));
  font-family: var(--headings-font-family);
  font-weight: bold;
  transition: color .6s ease-in-out;
}

html[dir="rtl"] h1, html[dir="rtl"] h2, html[dir="rtl"] h3, html[dir="rtl"] h4, html[dir="rtl"] h5, html[dir="rtl"] h6 {
  line-height: 1.4;
}

label {
  margin-bottom: .5rem;
  font-weight: bold;
}

p {
  line-height: 1.8rem;
}

@media (max-width: 767px) {
  .btns-container > * {
    width: 100%;
  }
}

@media (min-width: 768px) {
  html[dir="ltr"] .btns-container > :not(:last-of-type) {
    margin-right: 20px;
  }

  html[dir="rtl"] .btns-container > :not(:last-of-type) {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .btns-container > :not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.invert-in-light {
  -webkit-filter: var(--filter-invert-true);
  filter: var(--filter-invert-true);
}

.invert-in-dark {
  -webkit-filter: var(--filter-invert-false);
  filter: var(--filter-invert-false);
}

.form-styled .group:not(:last-child) {
  margin-bottom: 20px;
}

.form-styled .group label {
  color: hsl(var(--t-color-one));
  font-size: 12px;
}

.form-styled .group label.required-field:after {
  content: "*";
  color: hsl(var(--danger-color));
  font-family: monospace;
  font-size: 16px;
  line-height: 1;
}

html[dir="ltr"] .form-styled .group label.required-field:after {
  margin-left: 4px;
}

html[dir="rtl"] .form-styled .group label.required-field:after {
  margin-right: 4px;
}

.form-styled .group label .optional {
  color: hsl(var(--t-color-two));
  font-style: italic;
  font-weight: normal;
  font-family: var(--body-font-family);
}

.form-styled .group .control {
  position: relative;
}

html[dir="ltr"] .form-styled .group .control.has-prefix-icon input:not([type="radio"]):not([type="checkbox"]):not([type="file"]) {
  padding-left: 40px;
}

html[dir="rtl"] .form-styled .group .control.has-prefix-icon input:not([type="radio"]):not([type="checkbox"]):not([type="file"]) {
  padding-right: 40px;
}

.form-styled .group .control.has-prefix-icon textarea {
  text-indent: 26px;
}

.form-styled .group .control.has-prefix-icon .prefix-icon {
  width: 40px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

html[dir="ltr"] .form-styled .group .control.has-prefix-icon .prefix-icon {
  left: 0;
}

html[dir="rtl"] .form-styled .group .control.has-prefix-icon .prefix-icon {
  right: 0;
}

.form-styled .group .control.has-prefix-icon textarea + .prefix-icon {
  top: 11px;
  transform: translateY(0);
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]), .form-styled .group .control textarea {
  width: 100%;
  height: 40px;
  background-color: hsl(var(--inputs-bg-color));
  border: 1px solid;
  border-radius: .63rem;
  outline: 0;
  padding-left: 14px;
  padding-right: 14px;
  transition: all .3s ease-in-out;
  display: block;
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not(:focus), .form-styled .group .control textarea:not(:focus) {
  border-color: hsl(var(--inputs-bg-color));
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):focus, .form-styled .group .control textarea:focus {
  color: hsl(var(--main-color-one));
  border-color: hsl(var(--main-color-one));
  outline: none;
}

.enable-focus-style .form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):focus, .enable-focus-style .form-styled .group .control textarea:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):focus ~ .fa, .form-styled .group .control textarea:focus ~ .fa {
  color: hsl(var(--main-color-one));
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):disabled, .form-styled .group .control textarea:disabled {
  background-color: hsl(var(--bg-color-two));
  cursor: not-allowed;
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):disabled ~ .fa, .form-styled .group .control textarea:disabled ~ .fa {
  cursor: not-allowed;
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]).invalid, .form-styled .group .control textarea.invalid {
  color: hsl(var(--danger-color)) !important;
  border-color: hsl(var(--danger-color)) !important;
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]).invalid ~ .fa, .form-styled .group .control textarea.invalid ~ .fa {
  color: hsl(var(--danger-color)) !important;
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]).valid, .form-styled .group .control textarea.valid {
  color: hsl(var(--success-color)) !important;
  border-color: hsl(var(--success-color)) !important;
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]).valid ~ .fa, .form-styled .group .control textarea.valid ~ .fa {
  color: hsl(var(--success-color)) !important;
}

.form-styled .group .control input:not([type="radio"]):not([type="checkbox"]):not([type="file"]) {
  line-height: 1;
}

.form-styled .group .control input[type="tel"] {
  direction: ltr;
}

html[dir="rtl"] .form-styled .group .control input[type="tel"]::-webkit-input-placeholder {
  text-align: end;
}

html[dir="rtl"] .form-styled .group .control input[type="tel"]::-moz-placeholder {
  text-align: end;
}

html[dir="rtl"] .form-styled .group .control input[type="tel"]:-ms-input-placeholder {
  text-align: end;
}

html[dir="rtl"] .form-styled .group .control input[type="tel"]::placeholder {
  text-align: end;
}

.form-styled .group .control textarea {
  min-height: 140px;
  white-space: normal;
  resize: vertical;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-styled .group .control > .fa:not(.prefix-icon) {
  font-size: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form-styled .group .control > .fa:not(.prefix-icon).action-icon {
  cursor: pointer;
}

html[dir="ltr"] .form-styled .group .control > .fa:not(.prefix-icon).action-icon {
  right: 18px;
}

html[dir="rtl"] .form-styled .group .control > .fa:not(.prefix-icon).action-icon {
  left: 18px;
}

.form-styled .group .control .errors-msgs {
  display: none;
}

.toggle-switch-btn {
  height: 46px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: hsl(var(--bg-color-two));
  border: 1px solid hsl(var(--border-color));
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  border-radius: 30px;
  padding: 4px;
  display: flex;
  position: relative;
}

.toggle-switch-btn label {
  z-index: 1;
  min-width: 0;
  height: 100%;
  color: hsl(var(--t-color-one));
  white-space: nowrap;
  cursor: pointer;
  border-radius: 30px;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: bold;
  transition: color .2s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

.toggle-switch-btn .switcher-toggle {
  z-index: 0;
  height: 36px;
  background-color: hsl(var(--main-color-one));
  cursor: pointer;
  border-radius: 30px;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 4px;
}

html[dir="ltr"] .toggle-switch-btn .switcher-toggle {
  left: 4px;
}

html[dir="rtl"] .toggle-switch-btn .switcher-toggle {
  right: 4px;
}

.toggle-switch-btn input {
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;
}

.toggle-switch-btn input:checked + label {
  color: hsl(var(--light-color));
}

html[dir="ltr"] .toggle-switch-btn input:nth-of-type(2):checked ~ .switcher-toggle {
  transform: translateX(100%);
}

html[dir="rtl"] .toggle-switch-btn input:nth-of-type(2):checked ~ .switcher-toggle {
  transform: translateX(-100%);
}

html[dir="ltr"] .toggle-switch-btn input:nth-of-type(3):checked ~ .switcher-toggle {
  transform: translateX(200%);
}

html[dir="rtl"] .toggle-switch-btn input:nth-of-type(3):checked ~ .switcher-toggle {
  transform: translateX(-200%);
}

.toggle-switch-btn input:focus + label {
  outline: none;
}

.enable-focus-style .toggle-switch-btn input:focus + label {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--bg-color-reverse), 70%);
  box-shadow: 0 0 0 3px hsla(var(--bg-color-reverse), 70%);
}

.has-tooltip {
  position: relative;
}

.has-tooltip .custom-tooltip {
  z-index: 1;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  -webkit-filter: var(--drop-shadow);
  filter: var(--drop-shadow);
  border-radius: .63rem;
  padding: .8rem 1rem;
  font-size: .9rem;
  font-weight: bold;
  transition: all .3s;
  position: absolute;
}

.has-tooltip .custom-tooltip:after {
  content: "";
  background-color: hsl(var(--main-color-one));
  position: absolute;
}

.has-tooltip .custom-tooltip.custom-tooltip-top {
  top: 0;
}

html[dir="ltr"] .has-tooltip .custom-tooltip.custom-tooltip-top {
  left: 50%;
  transform: translate(-50%, -190%);
}

html[dir="rtl"] .has-tooltip .custom-tooltip.custom-tooltip-top {
  right: 50%;
  transform: translate(50%, -190%);
}

.has-tooltip .custom-tooltip.custom-tooltip-top:after {
  height: 10px;
  width: 15px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  bottom: 1px;
}

html[dir="ltr"] .has-tooltip .custom-tooltip.custom-tooltip-top:after {
  left: 50%;
  transform: translate(-50%, 100%);
}

html[dir="rtl"] .has-tooltip .custom-tooltip.custom-tooltip-top:after {
  right: 50%;
  transform: translate(50%, 100%);
}

.has-tooltip .custom-tooltip.custom-tooltip-bottom {
  bottom: 0;
}

html[dir="ltr"] .has-tooltip .custom-tooltip.custom-tooltip-bottom {
  left: 50%;
  transform: translate(-50%, 190%);
}

html[dir="rtl"] .has-tooltip .custom-tooltip.custom-tooltip-bottom {
  right: 50%;
  transform: translate(50%, 190%);
}

.has-tooltip .custom-tooltip.custom-tooltip-bottom:after {
  height: 10px;
  width: 15px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  top: 1px;
}

html[dir="ltr"] .has-tooltip .custom-tooltip.custom-tooltip-bottom:after {
  left: 50%;
  transform: translate(-50%, -100%);
}

html[dir="rtl"] .has-tooltip .custom-tooltip.custom-tooltip-bottom:after {
  right: 50%;
  transform: translate(50%, -100%);
}

.has-tooltip .custom-tooltip.custom-tooltip-left {
  top: 50%;
  transform: translateY(-50%);
}

html[dir="ltr"] .has-tooltip .custom-tooltip.custom-tooltip-left {
  right: calc(100% + 4rem);
}

html[dir="rtl"] .has-tooltip .custom-tooltip.custom-tooltip-left {
  left: calc(100% + 4rem);
}

.has-tooltip .custom-tooltip.custom-tooltip-left:after {
  height: 15px;
  width: 10px;
  top: 50%;
}

html[dir="ltr"] .has-tooltip .custom-tooltip.custom-tooltip-left:after {
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  right: 1px;
  transform: translate(100%, -50%);
}

html[dir="rtl"] .has-tooltip .custom-tooltip.custom-tooltip-left:after {
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
  left: 1px;
  transform: translate(-100%, -50%);
}

.has-tooltip .custom-tooltip.custom-tooltip-right {
  top: 50%;
  transform: translateY(-50%);
}

html[dir="ltr"] .has-tooltip .custom-tooltip.custom-tooltip-right {
  left: calc(100% + 4rem);
}

html[dir="rtl"] .has-tooltip .custom-tooltip.custom-tooltip-right {
  right: calc(100% + 4rem);
}

.has-tooltip .custom-tooltip.custom-tooltip-right:after {
  height: 15px;
  width: 10px;
  top: 50%;
}

html[dir="ltr"] .has-tooltip .custom-tooltip.custom-tooltip-right:after {
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
  left: 1px;
  transform: translate(-100%, -50%);
}

html[dir="rtl"] .has-tooltip .custom-tooltip.custom-tooltip-right:after {
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  right: 1px;
  transform: translate(100%, -50%);
}

.has-tooltip:hover, .enable-focus-style .has-tooltip:focus {
  z-index: 1;
}

.has-tooltip:hover .custom-tooltip, .enable-focus-style .has-tooltip:focus .custom-tooltip {
  visibility: visible;
  opacity: 1;
}

html[dir="ltr"] .has-tooltip:hover .custom-tooltip-top, html[dir="ltr"] .enable-focus-style .has-tooltip:focus .custom-tooltip-top {
  transform: translate(-50%, -150%);
}

html[dir="rtl"] .has-tooltip:hover .custom-tooltip-top, html[dir="rtl"] .enable-focus-style .has-tooltip:focus .custom-tooltip-top {
  transform: translate(50%, -150%);
}

html[dir="ltr"] .has-tooltip:hover .custom-tooltip-bottom, html[dir="ltr"] .enable-focus-style .has-tooltip:focus .custom-tooltip-bottom {
  transform: translate(-50%, 150%);
}

html[dir="rtl"] .has-tooltip:hover .custom-tooltip-bottom, html[dir="rtl"] .enable-focus-style .has-tooltip:focus .custom-tooltip-bottom {
  transform: translate(50%, 150%);
}

html[dir="ltr"] .has-tooltip:hover .custom-tooltip-left, html[dir="ltr"] .enable-focus-style .has-tooltip:focus .custom-tooltip-left {
  right: calc(100% + 2rem);
}

html[dir="rtl"] .has-tooltip:hover .custom-tooltip-left, html[dir="rtl"] .enable-focus-style .has-tooltip:focus .custom-tooltip-left, html[dir="ltr"] .has-tooltip:hover .custom-tooltip-right, html[dir="ltr"] .enable-focus-style .has-tooltip:focus .custom-tooltip-right {
  left: calc(100% + 2rem);
}

html[dir="rtl"] .has-tooltip:hover .custom-tooltip-right, html[dir="rtl"] .enable-focus-style .has-tooltip:focus .custom-tooltip-right {
  right: calc(100% + 2rem);
}

.has-ultimate-tooltip .ultimate-tooltip {
  background-color: hsl(var(--bg-color-two));
  text-align: center;
  -webkit-filter: var(--drop-shadow);
  filter: var(--drop-shadow);
  border-radius: .63rem;
  padding: .8rem 1rem;
}

@media (min-width: 577px) {
  .has-ultimate-tooltip .ultimate-tooltip {
    width: 300px;
  }
}

@media (max-width: 576px) {
  .has-ultimate-tooltip .ultimate-tooltip {
    width: 85vw;
  }
}

.has-ultimate-tooltip .ultimate-tooltip p {
  color: hsl(var(--t-color-two));
  font-size: 1.1rem;
  line-height: 1.7rem;
}

.has-ultimate-tooltip .ultimate-tooltip p:first-child {
  margin: 0 0 20px;
}

.has-ultimate-tooltip .ultimate-tooltip p:last-child {
  margin: 20px 0 0;
}

.has-ultimate-tooltip .ultimate-tooltip .ultimate-tooltip-title {
  color: hsl(var(--main-color-one));
  margin-bottom: 8px;
  font-size: 1.1rem;
}

.has-ultimate-tooltip .ultimate-tooltip .ultimate-tooltip-subtitle {
  margin: 0;
  font-size: .95rem;
}

.has-ultimate-tooltip .ultimate-tooltip[data-popper-placement="top"] + .ultimate-tooltip-arrow {
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  top: -21px;
}

html[dir="ltr"] .has-ultimate-tooltip .ultimate-tooltip[data-popper-placement="top"] + .ultimate-tooltip-arrow {
  transform: translate(-50%, -100%);
}

html[dir="rtl"] .has-ultimate-tooltip .ultimate-tooltip[data-popper-placement="top"] + .ultimate-tooltip-arrow {
  transform: translate(50%, -100%);
}

.has-ultimate-tooltip .ultimate-tooltip[data-popper-placement="bottom"] + .ultimate-tooltip-arrow {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  bottom: -21px;
}

html[dir="ltr"] .has-ultimate-tooltip .ultimate-tooltip[data-popper-placement="bottom"] + .ultimate-tooltip-arrow {
  transform: translate(-50%, 100%);
}

html[dir="rtl"] .has-ultimate-tooltip .ultimate-tooltip[data-popper-placement="bottom"] + .ultimate-tooltip-arrow {
  transform: translate(50%, 100%);
}

.has-ultimate-tooltip .ultimate-tooltip-arrow {
  height: 10px;
  width: 15px;
  background-color: hsl(var(--bg-color-two));
  position: absolute;
}

html[dir="ltr"] .has-ultimate-tooltip .ultimate-tooltip-arrow {
  left: 50%;
}

html[dir="rtl"] .has-ultimate-tooltip .ultimate-tooltip-arrow {
  right: 50%;
}

.has-ultimate-tooltip .ultimate-tooltip, .has-ultimate-tooltip .ultimate-tooltip-arrow {
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
}

.has-ultimate-tooltip:hover, .enable-focus-style .has-ultimate-tooltip:focus {
  z-index: 1;
}

.has-ultimate-tooltip:hover .ultimate-tooltip, .has-ultimate-tooltip:hover .ultimate-tooltip-arrow, .enable-focus-style .has-ultimate-tooltip:focus .ultimate-tooltip, .enable-focus-style .has-ultimate-tooltip:focus .ultimate-tooltip-arrow {
  visibility: visible;
  opacity: 1;
}

.circle-cursor {
  z-index: 99999999;
  mix-blend-mode: difference;
  pointer-events: none;
  visibility: hidden;
  border-radius: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

.circle-cursor.circle-cursor-outer {
  height: 30px;
  width: 30px;
  border: 1px solid hsl(var(--main-color-one));
  opacity: .5;
  transition: all 80ms ease-out;
}

.circle-cursor.circle-cursor-inner {
  height: 6px;
  width: 6px;
  background-color: hsl(var(--main-color-one));
  transition: top 20ms ease-out, left 20ms ease-out, height .2s ease-in-out, width .2s ease-in-out, opacity .2s ease-in-out;
}

.circle-cursor.circle-cursor-inner.cursor-link-hover {
  height: 20px;
  width: 20px;
  opacity: .5;
}

.circle-cursor.circle-cursor-inner.cursor-click-effect {
  height: 13px;
  width: 13px;
}

.preloader {
  z-index: 4000;
  height: 100%;
  width: 100%;
  background-color: hsl(var(--bg-color-two));
  display: flex;
  position: fixed;
  inset: 0;
}

.preloader .preloader-content {
  margin: auto;
}

.preloader .preloader-content .logo {
  text-align: center;
  margin-bottom: 25px;
}

.preloader .preloader-content .logo svg {
  width: 36px;
}

.preloader .preloader-content .logo svg path {
  fill: hsl(var(--bg-color-reverse));
}

.preloader .preloader-content .loading-bar {
  height: 4px;
  width: 180px;
  background-color: hsl(var(--bg-color-one));
  border-radius: 4px;
  overflow: hidden;
}

.preloader .preloader-content .loading-bar .loading-bar-progress {
  height: 100%;
  width: var(--loading-percentage, 0%);
  background-color: hsl(var(--bg-color-reverse));
  display: block;
}

.preloader ~ .hide-in-preloading {
  visibility: hidden;
}

header {
  z-index: 2000;
  width: 100%;
  transition: all .3s ease-in-out;
  position: fixed;
  left: 0;
  right: 0;
}

header > .container {
  height: 100px;
  justify-content: space-between;
  align-items: center;
  transition: all .3s ease-in-out;
  display: flex;
}

header .logo {
  z-index: 2;
  position: relative;
}

header .logo h1 {
  color: hsl(var(--bg-color-reverse));
  font-family: var(--logo-font-family);
  margin: 0;
  font-size: 2rem;
}

html[dir="ltr"] header .logo h1 {
  font-weight: normal;
}

header .logo h1:after {
  content: "";
  height: .17em;
  width: .2em;
  background-color: hsl(var(--main-color-one));
  display: inline-block;
}

html[dir="ltr"] header .logo h1:after {
  margin-left: .08em;
}

html[dir="rtl"] header .logo h1:after {
  margin-right: .08em;
}

header .logo h1:focus-within {
  outline: none;
}

.enable-focus-style header .logo h1:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {
  header nav .nav-links {
    align-items: center;
    display: flex;
  }
}

@media (max-width: 991px) {
  header nav .nav-links {
    z-index: 2;
    height: 100vh;
    width: 100vw;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 100px 40px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  html[dir="ltr"] header nav .nav-links li:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  html[dir="rtl"] header nav .nav-links li:not(:last-of-type) {
    margin-left: 1.6rem;
  }
}

@media (max-width: 991px) {
  header nav .nav-links li {
    width: 100%;
    overflow: hidden;
  }

  header nav .nav-links li:not(:last-of-type) {
    margin-bottom: .8rem;
  }
}

header nav .nav-links li a {
  color: hsl(var(--t-color-one));
  font-family: var(--headings-font-family);
  padding: .4rem .2rem;
  font-weight: bold;
  position: relative;
}

header nav .nav-links li a:before {
  content: "";
  height: .15rem;
  width: 0;
  background-color: hsl(var(--main-color-one));
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

html[dir="ltr"] header nav .nav-links li a:before {
  left: 0;
}

html[dir="rtl"] header nav .nav-links li a:before {
  right: 0;
}

header nav .nav-links li a:hover:before {
  width: 100%;
}

@media (min-width: 992px) {
  header nav .nav-links li a {
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  header nav .nav-links li a {
    font-size: 2rem;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.68, -.55, .27, 2);
    display: inline-block;
  }
}

header nav .nav-links li a:after {
  height: .2em;
  width: .2em;
  background-color: hsl(var(--t-color-one));
  border-radius: 50%;
  display: inline-block;
}

html[dir="ltr"] header nav .nav-links li a:after {
  margin-left: .1em;
}

html[dir="rtl"] header nav .nav-links li a:after {
  margin-right: .1em;
}

header nav .nav-links li a:focus {
  outline: none;
}

.enable-focus-style header nav .nav-links li a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

header nav:before {
  content: "";
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background-color: hsl(var(--bg-color-two));
  position: fixed;
  left: 0;
}

header nav .desktop-menu-content {
  z-index: 2;
  height: 100vh;
  width: 100vw;
  outline: none;
  position: fixed;
  left: 0;
}

@media (max-width: 991px) {
  header nav .desktop-menu-content {
    display: none;
  }
}

header nav .desktop-menu-content .container {
  height: 100vh;
  width: 100vw;
  place-items: center;
  padding-top: 80px;
  display: grid;
}

header nav .desktop-menu-content .container > * {
  width: 100%;
}

header nav .desktop-menu-content .about-us p {
  color: hsl(var(--t-color-one));
}

html[dir="ltr"] header nav .desktop-menu-content .about-us p {
  font-size: 1rem;
}

html[dir="rtl"] header nav .desktop-menu-content .about-us p {
  font-size: 1.1rem;
}

header nav .desktop-menu-content .about-us .block-title {
  font-size: 2.8rem;
  line-height: 4rem;
}

header nav .desktop-menu-content .contact-info li .block-title {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

header nav .desktop-menu-content .contact-info li .block-title:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: hsl(var(--main-color-one));
  position: absolute;
  top: 0;
}

header nav .desktop-menu-content .contact-info a {
  position: relative;
}

header nav .desktop-menu-content .contact-info a:before {
  content: "";
  height: .1rem;
  width: 0;
  background-color: hsl(var(--main-color-one));
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

html[dir="ltr"] header nav .desktop-menu-content .contact-info a:before {
  left: 0;
}

html[dir="rtl"] header nav .desktop-menu-content .contact-info a:before {
  right: 0;
}

header nav .desktop-menu-content .contact-info a:hover:before {
  width: 100%;
}

header nav .desktop-menu-content .contact-info a:focus {
  outline: none;
}

.enable-focus-style header nav .desktop-menu-content .contact-info a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {
  header nav:not(.menu-open):before, header nav:not(.menu-open) .desktop-menu-content {
    transition: all 1s cubic-bezier(.858, .01, .068, .99) .6s;
    top: 100vh;
  }
}

@media (max-width: 991px) {
  header nav:not(.menu-open):before, header nav:not(.menu-open) .desktop-menu-content, header nav:not(.menu-open) .nav-links {
    transition: all 1s cubic-bezier(.858, .01, .068, .99) .4s;
    top: 100vh;
  }

  header nav:not(.menu-open) .nav-links li a {
    transform: translateY(50px);
  }
}

header nav:not(.menu-open) .desktop-menu-content > * {
  visibility: hidden;
}

header nav:not(.menu-open) .desktop-menu-content .about-us p {
  opacity: 0;
  transition: all .2s ease-in-out .4s;
  transform: translateY(10px);
}

header nav:not(.menu-open) .desktop-menu-content .about-us .block-title {
  opacity: 0;
  transition: all .4s ease-in-out;
  transform: translateY(10px);
}

header nav:not(.menu-open) .desktop-menu-content .contact-info .block-title {
  color: #0000;
  transition: color 0s linear .2s;
}

header nav:not(.menu-open) .desktop-menu-content .contact-info .block-title:after {
  transition: all .4s linear;
}

html[dir="ltr"] header nav:not(.menu-open) .desktop-menu-content .contact-info .block-title:after {
  transition-property: left;
  left: -100%;
}

html[dir="rtl"] header nav:not(.menu-open) .desktop-menu-content .contact-info .block-title:after {
  transition-property: right;
  right: -100%;
}

header nav:not(.menu-open) .desktop-menu-content .contact-info ul {
  opacity: 0;
  transition: all .2s ease-in-out .4s;
  transform: translateY(15px);
}

header nav.menu-open:before, header nav.menu-open .desktop-menu-content {
  transition: all 1s cubic-bezier(.858, .01, .068, .99);
  top: 0;
}

@media (min-width: 992px) {
  header nav.menu-open .nav-links, header nav.menu-open ~ .options-icons .lang-switcher {
    visibility: hidden;
  }

  header nav.menu-open ~ .options-icons .lang-switcher {
    z-index: 0;
    opacity: 0;
  }
}

@media (max-width: 991px) {
  header nav.menu-open .nav-links {
    transition: all 1s cubic-bezier(.858, .01, .068, .99);
    top: 0;
  }

  header nav.menu-open .nav-links li a {
    transition-delay: .8s;
    transform: translateY(0);
  }
}

header nav.menu-open .desktop-menu-content > * {
  visibility: visible;
}

header nav.menu-open .desktop-menu-content .about-us p {
  opacity: 1;
  transition: all .2s ease-in-out 1s;
  transform: translateY(0);
}

header nav.menu-open .desktop-menu-content .about-us .block-title {
  opacity: 1;
  transition: all .4s ease-in-out 1.2s;
  transform: translateY(0);
}

header nav.menu-open .desktop-menu-content .contact-info .block-title {
  transition: color 0s linear 1.2s;
}

header nav.menu-open .desktop-menu-content .contact-info .block-title:after {
  transition: all .4s linear 1s;
}

html[dir="ltr"] header nav.menu-open .desktop-menu-content .contact-info .block-title:after {
  transition-property: left;
  left: 100%;
}

html[dir="rtl"] header nav.menu-open .desktop-menu-content .contact-info .block-title:after {
  transition-property: right;
  right: 100%;
}

header nav.menu-open .desktop-menu-content .contact-info ul {
  opacity: 1;
  transition: all .2s ease-in-out 1.4s;
  transform: translateY(0);
}

header .options-icons {
  align-items: center;
  display: flex;
}

html[dir="ltr"] header .options-icons > li:not(:last-of-type) {
  margin-right: 1rem;
}

html[dir="rtl"] header .options-icons > li:not(:last-of-type) {
  margin-left: 1rem;
}

header .options-icons .lang-switcher {
  transition: opacity 1s cubic-bezier(.858, .01, .068, .99);
}

header .options-icons .lang-switcher .lang-switcher-menu {
  position: relative;
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher .lang-switcher-menu {
    z-index: 1;
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher .lang-switcher-menu {
    z-index: 0;
  }
}

header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children > a:after {
  content: "";
  font-family: FontAwesome;
}

html[dir="ltr"] header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children > a:after {
  margin-left: 8px;
}

html[dir="rtl"] header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children > a:after {
  margin-right: 8px;
}

header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children > a:focus {
  outline: none;
}

.enable-focus-style header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children > a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
  background-color: hsl(var(--bg-color-two));
  z-index: -1;
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    -webkit-filter: drop-shadow(0 2px 6px #0000004d);
    filter: drop-shadow(0 2px 6px #0000004d);
    visibility: hidden;
    opacity: 0;
    border-radius: .63rem;
    padding: 6px 0;
    transition: all .3s ease-in-out;
    position: absolute;
    top: calc(100% + 7px);
  }

  html[dir="ltr"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    left: 50%;
    transform: translate(-50%, 20px);
  }

  html[dir="rtl"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    right: 50%;
    transform: translate(50%, 20px);
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before, header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    content: "";
    z-index: -1;
    display: block;
    position: absolute;
  }

  html[dir="ltr"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before, html[dir="ltr"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    left: 50%;
  }

  html[dir="rtl"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before, html[dir="rtl"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    right: 50%;
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before {
    height: 35px;
    width: 100%;
    top: -25px;
  }

  html[dir="ltr"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before {
    transform: translateX(-50%);
  }

  html[dir="rtl"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before {
    transform: translateX(50%);
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    height: 6px;
    width: 12px;
    background-color: inherit;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    top: -5px;
  }

  html[dir="ltr"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    transform: translateX(-50%);
  }

  html[dir="rtl"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    transform: translateX(50%);
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    width: 100%;
    border-top: 2px solid hsl(var(--main-color-one));
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
  }

  html[dir="ltr"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu, html[dir="rtl"] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    transform: translate(0, 20px);
  }
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:first-of-type:not(:only-of-type) {
    border-radius: .63rem .63rem 0 0;
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:last-of-type:not(:only-of-type) {
    border-radius: 0 0 .63rem .63rem;
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:only-of-type {
    border-radius: .63rem;
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:not(:last-of-type) {
    border-bottom: 1px solid hsl(var(--bg-color-one));
  }
}

header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item a {
  padding: 0 14px;
  font-weight: bold;
  display: block;
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item a {
    height: 30px;
    white-space: nowrap;
    line-height: 30px;
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item a {
    height: 40px;
    line-height: 40px;
  }
}

header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item a:hover {
  color: hsl(var(--main-color-one));
}

header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:focus-within {
  outline: none;
}

.enable-focus-style header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu, header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    visibility: visible;
    opacity: 1;
  }

  html[dir="ltr"] header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu, html[dir="ltr"] header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    transform: translate(-50%);
  }

  html[dir="rtl"] header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu, html[dir="rtl"] header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    transform: translate(50%);
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu, header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    visibility: visible;
    opacity: 1;
  }

  html[dir="ltr"] header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu, html[dir="ltr"] header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu, html[dir="rtl"] header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu, html[dir="rtl"] header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    transform: translate(0);
  }
}

header .options-icons .theme-switcher button {
  height: 28px;
  width: 48px;
  border-radius: 15px;
  transition: all .3s ease-in-out;
  position: relative;
}

header .options-icons .theme-switcher button:after {
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 50%;
  transition: all 1s ease-in-out;
  position: absolute;
  top: 3px;
}

header .options-icons .theme-switcher button.light_theme:after {
  left: 23px;
  box-shadow: inset 22px -20px #f9d71c;
}

header .options-icons .theme-switcher button.dark_theme:after {
  left: 3px;
  box-shadow: inset 9px -7px #fff;
}

header .options-icons .theme-switcher button:focus {
  outline: none;
}

.enable-focus-style header .options-icons .theme-switcher button:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

header .options-icons .hamburger-btn {
  z-index: 2;
  position: relative;
}

header .options-icons .hamburger-btn button {
  height: 20px;
  width: 16px;
  margin-bottom: 4px;
  position: relative;
}

header .options-icons .hamburger-btn span {
  width: 2px;
  background-color: hsl(var(--bg-color-reverse));
  border-radius: 5px;
  transition: all .5s cubic-bezier(.77, .2, .05, 1);
  display: block;
  position: absolute;
}

header .options-icons .hamburger-btn span:nth-of-type(1) {
  height: 50%;
  transform-origin: bottom;
  bottom: 50%;
}

html[dir="ltr"] header .options-icons .hamburger-btn span:nth-of-type(1) {
  left: 0;
}

html[dir="rtl"] header .options-icons .hamburger-btn span:nth-of-type(1) {
  right: 0;
}

header .options-icons .hamburger-btn span:nth-of-type(2) {
  height: 100%;
  transform-origin: 0 100%;
  top: 0;
}

html[dir="ltr"] header .options-icons .hamburger-btn span:nth-of-type(2) {
  left: 50%;
  transform: translateX(-50%);
}

html[dir="rtl"] header .options-icons .hamburger-btn span:nth-of-type(2) {
  right: 50%;
  transform: translateX(50%);
}

header .options-icons .hamburger-btn span:nth-of-type(3) {
  height: 50%;
  transform-origin: top;
  top: 50%;
}

html[dir="ltr"] header .options-icons .hamburger-btn span:nth-of-type(3) {
  right: 0;
}

html[dir="rtl"] header .options-icons .hamburger-btn span:nth-of-type(3) {
  left: 0;
}

html[dir="ltr"] header .options-icons .hamburger-btn:not(.open) button {
  transform: rotate(35deg);
}

html[dir="rtl"] header .options-icons .hamburger-btn:not(.open) button {
  transform: rotate(-35deg);
}

html[dir="ltr"] header .options-icons .hamburger-btn.open button {
  transform: rotate(45deg);
}

html[dir="rtl"] header .options-icons .hamburger-btn.open button {
  transform: rotate(-45deg);
}

html[dir="ltr"] header .options-icons .hamburger-btn.open span:nth-of-type(1) {
  transform: rotate(90deg)translateY(3px);
}

html[dir="rtl"] header .options-icons .hamburger-btn.open span:nth-of-type(1) {
  transform: rotate(-90deg)translateY(3px);
}

html[dir="ltr"] header .options-icons .hamburger-btn.open span:nth-of-type(3) {
  transform: rotate(90deg)translateY(-3px);
}

html[dir="rtl"] header .options-icons .hamburger-btn.open span:nth-of-type(3) {
  transform: rotate(-90deg)translateY(-3px);
}

header .options-icons .hamburger-btn span {
  transition: all 1s cubic-bezier(.858, .01, .068, .99);
}

header .options-icons .hamburger-btn:focus-within {
  outline: none;
}

.enable-focus-style header .options-icons .hamburger-btn:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {
  header.big-header .theme-switcher button {
    background-color: hsl(var(--bg-color-two));
  }

  header.small-header {
    background-color: hsl(var(--bg-color-two));
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);
  }

  header.small-header .container {
    height: 60px;
  }

  header.small-header .theme-switcher button {
    background-color: hsl(var(--bg-color-one));
  }
}

@media (max-width: 991px) {
  header {
    background-color: hsl(var(--bg-color-two));
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);
  }

  header .container {
    height: 60px;
  }

  header .theme-switcher button {
    background-color: hsl(var(--bg-color-one));
  }
}

header:not(.header-hidden) {
  top: 0;
}

@media (min-width: 992px) {
  header.header-hidden.big-header {
    top: -100px;
  }

  header.header-hidden.small-header {
    top: -60px;
  }
}

@media (max-width: 991px) {
  header.header-hidden {
    top: -60px;
  }
}

footer {
  background-color: hsl(var(--bg-color-two));
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: .95rem;
  line-height: 1.5rem;
}

footer .row {
  align-items: center;
}

@media (max-width: 991px) {
  footer .row {
    text-align: center;
  }

  footer .row > div:first-of-type {
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  footer .row > div:last-of-type {
    text-align: end;
  }
}

footer .row > div:last-of-type a {
  color: hsl(var(--main-color-one));
}

footer .row > div:last-of-type a:hover {
  text-decoration: underline;
}

footer .row > div:last-of-type a:focus {
  outline: none;
}

.enable-focus-style footer .row > div:last-of-type a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

footer .logo h1 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: hsl(var(--bg-color-reverse));
  font-family: var(--logo-font-family);
  margin: 0;
  font-size: 2rem;
}

html[dir="ltr"] footer .logo h1 {
  font-weight: normal;
}

@media (max-width: 991px) {
  footer .logo h1 {
    margin-left: auto;
    margin-right: auto;
  }
}

footer .logo h1:after {
  content: "";
  height: .17em;
  width: .2em;
  background-color: hsl(var(--main-color-one));
  display: inline-block;
}

html[dir="ltr"] footer .logo h1:after {
  margin-left: .08em;
}

html[dir="rtl"] footer .logo h1:after {
  margin-right: .08em;
}

footer .logo h1:focus-within {
  outline: none;
}

.enable-focus-style footer .logo h1:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.scroll-to-top {
  z-index: 1990;
  height: 46px;
  width: 46px;
  color: hsl(var(--primary-color));
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 2px hsla(var(--primary-color), 25%) inset;
  box-shadow: 0 0 0 2px hsla(var(--primary-color), 25%) inset;
  visibility: hidden;
  border-radius: 50%;
  font-size: 1.5rem;
  transition: none;
  position: fixed;
  bottom: 25px;
}

html[dir="ltr"] .scroll-to-top {
  right: 25px;
}

html[dir="rtl"] .scroll-to-top {
  left: 25px;
}

.scroll-to-top svg path {
  fill: none;
  stroke: hsl(var(--primary-color));
  stroke-width: 5px;
}

.scroll-to-top:after {
  content: "";
  height: 40%;
  width: 25%;
  background-color: hsl(var(--primary-color));
  clip-path: polygon(0 30%, 50% 0, 100% 30%, 94% 39%, 58% 17%, 58% 100%, 42% 100%, 42% 17%, 6% 39%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-to-top:focus {
  outline: none;
}

.enable-focus-style .scroll-to-top:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.ajax-loading {
  z-index: 2000;
  background-color: hsl(var(--bg-color-two));
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  border-radius: 50%;
  padding: 3px;
  position: fixed;
  bottom: 20px;
}

html[dir="ltr"] .ajax-loading {
  left: 20px;
}

html[dir="rtl"] .ajax-loading {
  right: 20px;
}

.ajax-loading span {
  height: 29px;
  width: 29px;
  border-style: solid;
  border-width: 3px;
  border-color: transparent hsl(var(--main-color-one));
  border-radius: 50%;
  display: block;
}

html[dir="ltr"] .ajax-loading span {
  animation: spinner-left .6s linear infinite;
}

html[dir="rtl"] .ajax-loading span {
  animation: spinner-right .6s linear infinite;
}

@keyframes timer {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.notifications-container {
  z-index: 3000;
  position: fixed;
  bottom: 25px;
}

html[dir="ltr"] .notifications-container {
  right: 80px;
}

html[dir="rtl"] .notifications-container {
  left: 80px;
}

@media (max-width: 576px) {
  .notifications-container {
    width: calc(100% - 30px);
    max-width: 250px;
  }
}

.notifications-container .notification {
  color: #fff;
  line-height: 1.5;
  position: relative;
}

html[dir="ltr"] .notifications-container .notification {
  padding: 10px 28px 10px 14px;
}

html[dir="rtl"] .notifications-container .notification {
  padding: 10px 14px 10px 28px;
}

@media (min-width: 577px) {
  .notifications-container .notification {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .notifications-container .notification {
    width: 100%;
  }
}

.notifications-container .notification.timer .disappearing-time {
  height: 3px;
  background-color: inherit;
  opacity: .6;
  animation-name: timer;
  animation-timing-function: linear;
  animation-fill-mode: both;
  display: block;
  position: absolute;
  bottom: -3px;
}

html[dir="ltr"] .notifications-container .notification.timer .disappearing-time {
  left: 0;
}

html[dir="rtl"] .notifications-container .notification.timer .disappearing-time {
  right: 0;
}

.notifications-container .notification.success {
  background-color: hsl(var(--success-color));
}

.notifications-container .notification.danger {
  background-color: hsl(var(--danger-color));
}

.notifications-container .notification.warning {
  background-color: hsl(var(--warning-color));
  color: hsl(var(--dark-color));
}

.notifications-container .notification.info {
  background-color: hsl(var(--info-color));
}

.notifications-container .notification:not(:last-of-type) {
  margin-bottom: 10px;
}

.notifications-container .notification .fa {
  cursor: pointer;
  opacity: .7;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

html[dir="ltr"] .notifications-container .notification .fa {
  right: 12px;
}

html[dir="rtl"] .notifications-container .notification .fa {
  left: 12px;
}

.notifications-container .notification .fa:hover {
  opacity: 1;
}

.notifications-container .notify-enter-active {
  animation: none;
}

html[dir="ltr"] .notifications-container .notify-enter-active {
  animation-name: slideInLeft;
}

html[dir="rtl"] .notifications-container .notify-enter-active {
  animation-name: slideInRight;
}

.notifications-container .notify-leave-active {
  animation: none;
}

html[dir="ltr"] .notifications-container .notify-leave-active {
  animation-name: slideInLeft;
}

html[dir="rtl"] .notifications-container .notify-leave-active {
  animation-name: slideInRight;
}

.notifications-container .notify-move {
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

@media (max-width: 991px) {
  .hero-section .hero-img {
    height: 80vw;
    max-height: 400px;
    width: 80vw;
    max-width: 400px;
    margin: auto;
    position: relative;
  }
}

.hero-section .hero-img .layer {
  width: 100%;
  position: absolute;
}

@media (min-width: 992px) {
  .hero-section .hero-img .layer {
    transition: transform 1s, -webkit-transform 1s;
    top: 0;
    left: 0;
  }
}

@media (max-width: 991px) {
  html[dir="ltr"] .hero-section .hero-img .layer {
    left: 50%;
  }

  html[dir="rtl"] .hero-section .hero-img .layer {
    right: 50%;
  }
}

.hero-section .hero-img .layer:first-of-type {
  height: 100%;
  -webkit-mask-image: url("splash.9fda3eab.png");
  mask-image: url("splash.9fda3eab.png");
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
}

.hero-section .hero-img .layer:first-of-type:before {
  content: "";
  position: absolute;
}

@media (min-width: 992px) {
  .hero-section .hero-img .layer:first-of-type:before {
    z-index: 1;
    height: 100%;
    width: 100%;
    background: -webkit-gradient(linear, left top, right top, from(hsl(var(--main-color-one)) ), to(hsl(var(--main-color-two)) ) );
    background: linear-gradient(to right, hsl(var(--main-color-one)) 0%, hsl(var(--main-color-two)) 100%);
    top: 0;
    left: 0;
  }
}

@media (max-width: 991px) {
  .hero-section .hero-img .layer:first-of-type {
    top: 50%;
  }

  html[dir="ltr"] .hero-section .hero-img .layer:first-of-type {
    transform: translate(-50%, -50%);
  }

  html[dir="rtl"] .hero-section .hero-img .layer:first-of-type {
    transform: translate(50%, -50%);
  }

  .hero-section .hero-img .layer:first-of-type:before {
    height: 80%;
    width: 80%;
    background-color: hsl(var(--main-color-one));
    border-radius: 50%;
    bottom: 0;
  }

  html[dir="ltr"] .hero-section .hero-img .layer:first-of-type:before {
    left: 50%;
    transform: translateX(-50%);
  }

  html[dir="rtl"] .hero-section .hero-img .layer:first-of-type:before {
    right: 50%;
    transform: translateX(50%);
  }
}

.hero-section .hero-img .layer:last-of-type {
  height: 50%;
  overflow: hidden;
}

@media (max-width: 991px) {
  .hero-section .hero-img .layer:last-of-type {
    top: 0;
  }

  html[dir="ltr"] .hero-section .hero-img .layer:last-of-type {
    transform: translateX(-50%);
  }

  html[dir="rtl"] .hero-section .hero-img .layer:last-of-type {
    transform: translateX(50%);
  }

  .hero-section .hero-img .layer:last-of-type img {
    height: 200%;
    width: 200%;
  }

  html[dir="ltr"] .hero-section .hero-img .layer:last-of-type img {
    transform: translateX(-25%);
  }

  html[dir="rtl"] .hero-section .hero-img .layer:last-of-type img {
    transform: translateX(25%);
  }
}

.hero-section .hero-img .layer img {
  -webkit-filter: drop-shadow(2px 4px 6px #000);
  filter: drop-shadow(2px 4px 6px #000);
  display: block;
}

@media (min-width: 992px) {
  .hero-section .hero-img .layer img {
    z-index: 2;
    height: calc(95vh - 70px);
    max-width: 100%;
    user-select: none;
    margin: 3rem auto auto;
    position: relative;
    transform: translateX(-8rem);
  }
}

@media (max-width: 991px) {
  .hero-section .hero-img .layer img {
    height: 100%;
    width: 75%;
    -o-object-fit: contain;
    object-fit: contain;
    margin-left: 25px;
  }
}

.hero-section .hero-text {
  text-align: center;
}

@media (min-width: 992px) {
  .hero-section .hero-text {
    z-index: 3;
    width: 90%;
    text-shadow: 0px 0px 20px hsl(var(--dark-color));
    position: absolute;
    top: 45%;
  }

  html[dir="ltr"] .hero-section .hero-text {
    left: 50%;
    transform: translateX(-50%);
  }

  html[dir="rtl"] .hero-section .hero-text {
    right: 50%;
    transform: translateX(50%);
  }
}

@media (max-width: 991px) {
  .hero-section .hero-text {
    width: 90vw;
  }
}

.hero-section .hero-text h2 {
  margin-bottom: 30px;
}

html[dir="ltr"] .hero-section .hero-text h2 {
  text-transform: capitalize;
}

@media (min-width: 992px) {
  .hero-section .hero-text h2 {
    font-size: 3rem;
    color: hsl(var(--light-color)) !important;
  }
}

@media (max-width: 991px) {
  .hero-section .hero-text h2 {
    color: hsl(var(--bg-color-reverse));
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .hero-section .hero-text .btn {
    color: hsl(var(--light-color));
    border-color: hsl(var(--light-color));
  }

  .hero-section .hero-text .btn:hover {
    background-color: hsl(var(--light-color));
    color: hsl(var(--dark-color));
    text-shadow: none;
  }
}

@media (max-width: 991px) {
  .hero-section .hero-text .btn {
    color: hsl(var(--bg-color-reverse));
    border-color: hsl(var(--bg-color-reverse));
  }

  .hero-section .hero-text .btn:hover {
    background-color: hsl(var(--bg-color-reverse));
    color: hsl(var(--bg-color-one));
  }
}

.hero-section .hero-text .btn:focus {
  outline: none;
}

.enable-focus-style .hero-section .hero-text .btn:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.hero-section .social, .hero-section .scroll-down {
  text-transform: uppercase;
  align-items: center;
  font-size: 18px;
  display: flex;
  position: absolute;
  bottom: 20px;
}

@media (max-width: 991px) {
  .hero-section .social, .hero-section .scroll-down {
    display: none;
  }
}

.hero-section .social.social:before, .hero-section .social.scroll-down:after, .hero-section .scroll-down.social:before, .hero-section .scroll-down.scroll-down:after {
  content: "";
  height: 2px;
  width: 30px;
  background-color: hsl(var(--t-color-two));
  transition: all .3s ease-in-out;
  display: inline-block;
}

.hero-section .social.social a:hover, .hero-section .social.scroll-down:hover, .hero-section .scroll-down.social a:hover, .hero-section .scroll-down.scroll-down:hover {
  color: hsl(var(--t-color-one));
}

.hero-section .social.social a:hover:after, .hero-section .social.scroll-down:hover:after, .hero-section .scroll-down.social a:hover:after, .hero-section .scroll-down.scroll-down:hover:after {
  background-color: hsl(var(--t-color-one));
}

html[dir="ltr"] .hero-section .social {
  transform-origin: 0 0;
  left: 30px;
  transform: rotate(-90deg);
}

html[dir="rtl"] .hero-section .social {
  transform-origin: 100% 0;
  right: 30px;
  transform: rotate(90deg);
}

html[dir="ltr"] .hero-section .social:before {
  margin-right: 20px;
}

html[dir="rtl"] .hero-section .social:before {
  margin-left: 20px;
}

.hero-section .social ul {
  display: flex;
}

html[dir="ltr"] .hero-section .social ul {
  transform: rotateY(180deg);
}

html[dir="rtl"] .hero-section .social ul {
  transform: rotateY(-180deg);
}

html[dir="ltr"] .hero-section .social ul li {
  transform: rotateX(0deg)rotateY(180deg)rotateZ(90deg);
}

html[dir="rtl"] .hero-section .social ul li {
  transform: rotateX(0deg)rotateY(-180deg)rotateZ(-90deg);
}

html[dir="ltr"] .hero-section .social ul li:not(:last-of-type) {
  margin-right: 20px;
}

html[dir="rtl"] .hero-section .social ul li:not(:last-of-type) {
  margin-left: 20px;
}

.hero-section .social ul li a:focus {
  outline: none;
}

.enable-focus-style .hero-section .social ul li a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

html[dir="ltr"] .hero-section .scroll-down {
  transform-origin: 100% 0;
  right: 30px;
  transform: rotate(90deg);
}

html[dir="rtl"] .hero-section .scroll-down {
  transform-origin: 0 0;
  left: 30px;
  transform: rotate(-90deg);
}

.hero-section .scroll-down a {
  text-transform: inherit;
}

.hero-section .scroll-down a:focus {
  outline: none;
}

.enable-focus-style .hero-section .scroll-down a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

html[dir="ltr"] .hero-section .scroll-down:after {
  margin-left: 20px;
}

html[dir="rtl"] .hero-section .scroll-down:after {
  margin-right: 20px;
}

@media (min-width: 992px) {
  .hero-section {
    height: calc(100vh - 70px);
    position: relative;
  }

  .hero-section.parallax-animation:hover .layer {
    transform: scale(1.05);
  }
}

@media (max-width: 991px) {
  .hero-section {
    min-height: calc(100vh - 70px);
    place-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
    display: grid;
  }
}

.statistics-section .section-content .statistics-items {
  margin-bottom: -30px;
}

.statistics-section .section-content .statistics-items li {
  visibility: hidden;
  margin-bottom: 30px;
}

.statistics-section .section-content .statistics-items li:nth-of-type(1) .icon {
  background-color: hsl(var(--bg-color-reverse));
  color: hsl(var(--bg-color-one));
}

.statistics-section .section-content .statistics-items li:nth-of-type(1) .icon img {
  -webkit-filter: var(--filter-invert-true);
  filter: var(--filter-invert-true);
}

.statistics-section .section-content .statistics-items li:nth-of-type(2) .icon {
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
}

.statistics-section .section-content .statistics-items li:nth-of-type(2) .icon img {
  -webkit-filter: invert();
  filter: invert();
}

.statistics-section .section-content .statistics-items li:nth-of-type(3) .icon {
  background-color: hsl(var(--bg-color-one));
  color: hsl(var(--bg-color-reverse));
}

.statistics-section .section-content .statistics-items li:nth-of-type(3) .icon img {
  -webkit-filter: var(--filter-invert-false);
  filter: var(--filter-invert-false);
}

.statistics-section .section-content .statistics-items li:hover a {
  margin-top: -15px;
}

.statistics-section .section-content .statistics-items li:hover a > .fa {
  color: hsl(var(--t-color-one));
}

.statistics-section .section-content .statistics-items a {
  height: 100%;
  background-color: hsl(var(--bg-color-two));
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  border-radius: .63rem;
  align-items: center;
  padding: 30px;
  display: flex;
}

.statistics-section .section-content .statistics-items a .icon {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.statistics-section .section-content .statistics-items a .icon img {
  width: 40px;
}

.statistics-section .section-content .statistics-items a .text {
  width: calc(100% - 124px);
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.statistics-section .section-content .statistics-items a .text h3 {
  color: hsl(var(--main-color-one));
  font-size: 2rem;
}

.statistics-section .section-content .statistics-items a > .fa {
  font-size: 1.2rem;
  transition: all .3s ease-in-out;
}

html[dir="rtl"] .statistics-section .section-content .statistics-items a > .fa {
  transform: rotateY(180deg);
}

.statistics-section .section-content .statistics-items a:focus {
  outline: none;
}

.enable-focus-style .statistics-section .section-content .statistics-items a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.about-section .section-content {
  align-items: center;
  margin-bottom: -50px;
}

.about-section .section-content > * {
  margin-bottom: 50px;
}

.about-section .section-content .about-img {
  visibility: hidden;
  position: relative;
}

@media (min-width: 992px) {
  .about-section .section-content .about-img {
    height: 500px;
  }
}

@media (max-width: 991px) {
  .about-section .section-content .about-img {
    height: 400px;
  }
}

.about-section .section-content .about-img .layer {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.about-section .section-content .about-img .layer:first-of-type {
  height: 100%;
  overflow: hidden;
  -webkit-mask-image: url("blob-shape.0929dc01.png");
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
}

.about-section .section-content .about-img .layer:first-of-type:before {
  content: "";
  z-index: 1;
  height: 80%;
  width: 80%;
  background: linear-gradient(150deg, hsl(var(--main-color-one)) 0%, hsl(var(--main-color-two)) 100%);
  position: absolute;
  bottom: 0;
  -webkit-mask-image: url("blob-shape.0929dc01.png");
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
}

html[dir="ltr"] .about-section .section-content .about-img .layer:first-of-type:before {
  left: 50%;
  transform: translateX(-50%);
}

html[dir="rtl"] .about-section .section-content .about-img .layer:first-of-type:before {
  right: 50%;
  transform: translateX(50%);
}

.about-section .section-content .about-img .layer:first-of-type img {
  height: 100%;
  -webkit-filter: drop-shadow(2px 4px 6px #000);
  filter: drop-shadow(2px 4px 6px #000);
}

.about-section .section-content .about-img .layer:last-of-type {
  height: 50%;
  overflow: hidden;
}

.about-section .section-content .about-img .layer:last-of-type img {
  height: 200%;
}

.about-section .section-content .about-img .layer img {
  z-index: 2;
  max-width: 100%;
  user-select: none;
  margin: auto;
  display: block;
  position: relative;
}

.about-section .section-content .about-text p {
  margin: 0;
}

.skills-section .section-content {
  align-items: center;
  margin-bottom: -50px;
}

.skills-section .section-content > * {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .skills-section .section-content .skills-text .toggle-switch-btn {
    margin: auto;
  }
}

.skills-section .section-content .skills-items ul {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -8px -16px;
  animation: fadeIn .3s ease-out;
  display: flex;
}

.skills-section .section-content .skills-items li {
  height: 80px;
  width: 80px;
  background-color: hsl(var(--bg-color-two));
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  cursor: help;
  visibility: hidden;
  border-radius: .63rem;
  justify-content: center;
  align-items: center;
  margin: 0 8px 16px;
  display: flex;
}

.skills-section .section-content .skills-items li .skill-icon img {
  width: 48px;
  margin: auto;
  display: block;
}

.skills-section .section-content .skills-items li:focus {
  outline: none;
}

.enable-focus-style .skills-section .section-content .skills-items li:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.experience-section .section-content {
  align-items: center;
  margin-bottom: -50px;
}

@media (max-width: 991px) {
  .experience-section .section-content {
    flex-direction: column-reverse;
  }
}

.experience-section .section-content > * {
  margin-bottom: 50px;
}

.experience-section .section-content .experience-timeline {
  position: relative;
}

@media (min-width: 992px) {
  html[dir="ltr"] .experience-section .section-content .experience-timeline {
    padding-left: 30px;
    padding-right: 40px;
  }

  html[dir="rtl"] .experience-section .section-content .experience-timeline {
    padding-left: 40px;
    padding-right: 30px;
  }
}

@media (max-width: 991px) {
  .experience-section .section-content .experience-timeline {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.experience-section .section-content .experience-timeline .timepath {
  height: 100%;
  width: calc(100% - 10px);
  align-content: center;
  display: grid;
  position: absolute;
  top: 0;
}

html[dir="ltr"] .experience-section .section-content .experience-timeline .timepath {
  left: 0;
}

html[dir="rtl"] .experience-section .section-content .experience-timeline .timepath {
  right: 0;
}

.experience-section .section-content .experience-timeline .timepath span {
  visibility: hidden;
}

.experience-section .section-content .experience-timeline .timepath .line {
  width: calc(100% - 60px);
  border-top: 7px solid hsl(var(--border-color));
  margin: auto;
  display: block;
}

.experience-section .section-content .experience-timeline .timepath .line:not(:first-of-type) {
  margin-top: -7px;
}

.experience-section .section-content .experience-timeline .timepath .semicircle {
  height: 87px;
  width: 80px;
  border-style: solid;
  border-color: hsl(var(--border-color));
  margin-top: -7px;
  display: block;
}

html[dir="ltr"] .experience-section .section-content .experience-timeline .timepath .semicircle:nth-of-type(4n+2) {
  border-width: 7px 7px 7px 0;
  border-radius: 0 2em 2em 0;
  margin-left: auto;
}

html[dir="rtl"] .experience-section .section-content .experience-timeline .timepath .semicircle:nth-of-type(4n+2), html[dir="ltr"] .experience-section .section-content .experience-timeline .timepath .semicircle:nth-of-type(4n+4) {
  border-width: 7px 0 7px 7px;
  border-radius: 2em 0 0 2em;
  margin-right: auto;
}

html[dir="rtl"] .experience-section .section-content .experience-timeline .timepath .semicircle:nth-of-type(4n+4) {
  border-width: 7px 7px 7px 0;
  border-radius: 0 2em 2em 0;
  margin-left: auto;
}

.experience-section .section-content .experience-timeline .timeline-items {
  grid-template-columns: auto auto auto;
  grid-auto-rows: 80px;
  grid-auto-flow: dense;
  counter-reset: items-count;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

@media (min-width: 992px) {
  html[dir="ltr"] .experience-section .section-content .experience-timeline .timeline-items {
    padding-left: 30px;
  }

  html[dir="rtl"] .experience-section .section-content .experience-timeline .timeline-items {
    padding-right: 30px;
  }
}

.experience-section .section-content .experience-timeline .timeline-items li {
  z-index: 1;
  height: 30px;
  cursor: help;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.experience-section .section-content .experience-timeline .timeline-items li:nth-of-type(6n+4) {
  grid-column: 3;
}

.experience-section .section-content .experience-timeline .timeline-items li:nth-of-type(6n+5) {
  grid-column: 2;
}

.experience-section .section-content .experience-timeline .timeline-items li:nth-of-type(6n+6) {
  grid-column: 1;
}

@media (min-width: 992px) {
  .experience-section .section-content .experience-timeline .timeline-items li {
    width: 70px;
    background-color: hsl(var(--bg-color-two));
    color: hsl(var(--t-color-one));
    counter-increment: items-count;
    -webkit-filter: var(--drop-shadow);
    filter: var(--drop-shadow);
  }

  html[dir="ltr"] .experience-section .section-content .experience-timeline .timeline-items li {
    border-radius: 0 .63rem .63rem 0;
  }

  html[dir="rtl"] .experience-section .section-content .experience-timeline .timeline-items li {
    border-radius: .63rem 0 0 .63rem;
  }

  .experience-section .section-content .experience-timeline .timeline-items li:before {
    content: counter(items-count);
    height: 40px;
    width: 40px;
    background-color: hsl(var(--main-color-one));
    color: hsl(var(--light-color));
    -webkit-filter: var(--drop-shadow);
    filter: var(--drop-shadow);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    position: absolute;
    top: -5px;
  }

  html[dir="ltr"] .experience-section .section-content .experience-timeline .timeline-items li:before {
    left: -30px;
  }

  html[dir="rtl"] .experience-section .section-content .experience-timeline .timeline-items li:before {
    right: -30px;
  }
}

@media (max-width: 991px) {
  .experience-section .section-content .experience-timeline .timeline-items li {
    background-color: hsl(var(--main-color-one));
    color: hsl(var(--light-color));
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);
    border-radius: .63rem;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.experience-section .section-content .experience-timeline .timeline-items li h3 {
  color: inherit;
  margin: 0;
  font-size: 1rem;
}

html[dir="ltr"] .experience-section .section-content .experience-timeline .timeline-items li:empty:after {
  content: "?";
}

html[dir="rtl"] .experience-section .section-content .experience-timeline .timeline-items li:empty:after {
  content: "؟";
}

.experience-section .section-content .experience-timeline .timeline-items li:hover, .experience-section .section-content .experience-timeline .timeline-items li:focus-within {
  z-index: 2;
}

.experience-section .section-content .experience-timeline .timeline-items li:focus {
  outline: none;
}

.enable-focus-style .experience-section .section-content .experience-timeline .timeline-items li:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.portfolio-section .section-content .portfolio-items.has-works {
  -webkit-column-gap: 25px;
  -moz-column-gap: 25px;
  column-gap: 25px;
}

@media (min-width: 992px) {
  .portfolio-section .section-content .portfolio-items.has-works {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 991px) {
  .portfolio-section .section-content .portfolio-items.has-works {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 576px) {
  .portfolio-section .section-content .portfolio-items.has-works {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.portfolio-section .section-content .portfolio-items.has-works > li {
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .portfolio-section .section-content .portfolio-items.has-works > li:first-of-type {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    overflow: hidden;
  }
}

.portfolio-section .section-content .portfolio-items:not(.has-works) {
  margin-bottom: -50px;
}

.portfolio-section .section-content .portfolio-items:not(.has-works) > li {
  margin-bottom: 50px;
}

.portfolio-section .section-content .portfolio-items .works-filter {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

@media (max-width: 576px) {
  .portfolio-section .section-content .portfolio-items .works-filter {
    justify-content: center;
  }
}

html[dir="ltr"] .portfolio-section .section-content .portfolio-items .works-filter li:not(:last-of-type) {
  margin-right: 8px;
}

html[dir="rtl"] .portfolio-section .section-content .portfolio-items .works-filter li:not(:last-of-type) {
  margin-left: 8px;
}

html[dir="ltr"] .portfolio-section .section-content .portfolio-items .works-filter li:not(:last-of-type):after {
  content: "/";
  margin-left: 8px;
}

html[dir="rtl"] .portfolio-section .section-content .portfolio-items .works-filter li:not(:last-of-type):after {
  content: "\\";
  margin-right: 8px;
}

.portfolio-section .section-content .portfolio-items .works-filter li button.active, .portfolio-section .section-content .portfolio-items .works-filter li button:hover {
  color: hsl(var(--main-color-one));
}

.portfolio-section .section-content .portfolio-items .works-filter li button:focus {
  outline: none;
}

.enable-focus-style .portfolio-section .section-content .portfolio-items .works-filter li button:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.portfolio-section .section-content .portfolio-items > li.portfolio-item {
  -webkit-filter: var(--drop-shadow);
  filter: var(--drop-shadow);
  will-change: transform;
  border-radius: .63rem;
  animation: zoomIn .2s ease-in-out;
  overflow: hidden;
}

.portfolio-section .section-content .portfolio-items > li.portfolio-item a {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
}

.portfolio-section .section-content .portfolio-items > li.portfolio-item a:hover .item-details {
  visibility: visible;
  opacity: 1;
  transition: all .3s;
}

.portfolio-section .section-content .portfolio-items > li.portfolio-item a:hover .item-details .title, .portfolio-section .section-content .portfolio-items > li.portfolio-item a:hover .item-details .date {
  opacity: 1;
  transform: translateY(0);
}

.portfolio-section .section-content .portfolio-items > li.portfolio-item a:hover .item-details .title {
  transition: all .2s linear .1s;
}

.portfolio-section .section-content .portfolio-items > li.portfolio-item a:hover .item-details .date {
  transition: all .2s linear .2s;
}

.portfolio-section .section-content .portfolio-items > li.portfolio-item:focus-within {
  outline: none;
}

.enable-focus-style .portfolio-section .section-content .portfolio-items > li.portfolio-item:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {
  .portfolio-section .section-content .portfolio-items > li [class*="text-box"] {
    margin-top: 37.5px;
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .portfolio-section .section-content .portfolio-items > li [class*="text-box"] {
    -webkit-column-span: all;
    -moz-column-span: all;
    column-span: all;
    padding-bottom: 37.5px;
    position: relative;
  }
}

.portfolio-section .section-content .portfolio-items > li .more {
  width: 100%;
  color: hsl(var(--main-color-one));
  text-align: center;
  background-color: #272727;
  border-radius: .63rem;
  padding: 10px;
  font-size: 1.4rem;
  text-decoration: none;
  display: block;
}

.portfolio-section .section-content .portfolio-items > li .more:hover {
  transform: scale(1.04);
}

.portfolio-section .section-content .portfolio-items > li .more:focus {
  outline: none;
}

.enable-focus-style .portfolio-section .section-content .portfolio-items > li .more:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.portfolio-section .section-content .portfolio-items > li .item-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
}

.portfolio-section .section-content .portfolio-items > li .item-details {
  z-index: 1;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 20px;
  transition: all .3s .2s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.portfolio-section .section-content .portfolio-items > li .item-details .title, .portfolio-section .section-content .portfolio-items > li .item-details .date {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: hsl(var(--bg-color-one));
  color: hsl(var(--bg-color-reverse));
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  opacity: 0;
  padding: 6px 12px;
  transform: translateY(30px);
}

.portfolio-section .section-content .portfolio-items > li .item-details .title {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  transition: all .2s linear .1s;
  overflow: hidden;
}

.portfolio-section .section-content .portfolio-items > li .item-details .date {
  font-size: .8rem;
  transition: all .2s linear;
}

@media (max-width: 991px) {
  .portfolio-section:not(.archive-page) .portfolio-items > li:first-of-type {
    margin-bottom: 0;
  }
}

.contact-section .section-content {
  align-items: center;
}

.contact-section .section-content > * {
  margin-bottom: 50px;
}

.contact-section .section-content .contact-text .contact-info {
  margin-top: 50px;
}

.contact-section .section-content .contact-text .contact-info > li {
  visibility: hidden;
  align-items: flex-start;
  display: flex;
}

.contact-section .section-content .contact-text .contact-info > li:not(:last-of-type) {
  margin-bottom: 20px;
}

.contact-section .section-content .contact-text .contact-info > li img {
  width: 24px;
  -webkit-filter: var(--filter-invert-false);
  filter: var(--filter-invert-false);
}

html[dir="ltr"] .contact-section .section-content .contact-text .contact-info > li img {
  margin-right: 18px;
}

html[dir="rtl"] .contact-section .section-content .contact-text .contact-info > li img {
  margin-left: 18px;
}

.contact-section .section-content .contact-text .contact-info > li div strong {
  margin-bottom: 5px;
  display: block;
}

.contact-section .section-content .contact-text .contact-info > li div a:hover {
  text-decoration: underline;
}

.contact-section .section-content .contact-text .contact-info > li div a:focus {
  outline: none;
}

.enable-focus-style .contact-section .section-content .contact-text .contact-info > li div a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.contact-section .section-content .contact-text .social {
  flex-wrap: wrap;
  align-items: center;
  margin: 30px -5px -10px;
  display: flex;
}

html[dir="ltr"] .contact-section .section-content .contact-text .social {
  padding-left: 42px;
}

html[dir="rtl"] .contact-section .section-content .contact-text .social {
  padding-right: 42px;
}

.contact-section .section-content .contact-text .social li {
  visibility: hidden;
}

.contact-section .section-content .contact-text .social li a {
  height: 35px;
  width: 35px;
  background-color: hsl(var(--bg-color-reverse));
  color: hsl(var(--bg-color-one));
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 5px 10px;
  display: flex;
}

.contact-section .section-content .contact-text .social li a:hover {
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
}

.contact-section .section-content .contact-text .social li a:focus {
  outline: none;
}

.enable-focus-style .contact-section .section-content .contact-text .social li a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.contact-section .section-content .contact-form {
  background-color: hsl(var(--bg-color-two));
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  visibility: hidden;
  border-radius: .63rem;
  margin: auto;
  padding: 25px;
}

@media (min-width: 768px) {
  .contact-section .section-content .contact-form {
    max-width: 450px;
  }
}

html[dir="rtl"] .contact-section .section-content .contact-form .phone-number .fa {
  transform: rotateY(180deg)translateY(-50%);
}

.contact-section .section-content .contact-form .submit-btn {
  width: 100%;
}

.portfolio-page .portfolio-items .work-tools {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -5px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
}

@media (max-width: 576px) {
  .portfolio-page .portfolio-items .work-tools {
    justify-content: center;
  }
}

.portfolio-page .portfolio-items .work-tools li {
  margin-bottom: 5px;
}

html[dir="ltr"] .portfolio-page .portfolio-items .work-tools li:not(:last-of-type) {
  margin-right: 5px;
}

html[dir="rtl"] .portfolio-page .portfolio-items .work-tools li:not(:last-of-type) {
  margin-left: 5px;
}

.portfolio-page .portfolio-items .work-tools li a {
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
  border-radius: .63rem;
  padding: 4px 8px;
  font-size: .75rem;
  display: block;
}

.portfolio-page .portfolio-items .work-tools li a:hover {
  background-color: hsl(var(--bg-color-reverse));
  color: hsl(var(--bg-color-one));
}

.portfolio-page .portfolio-items .work-tools li a:focus {
  outline: none;
}

.enable-focus-style .portfolio-page .portfolio-items .work-tools li a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.not-found-page {
  display: flex;
}

.not-found-page .not-found-content {
  text-align: center;
  margin: auto;
}

.not-found-page .not-found-content .not-found-img {
  margin-bottom: 50px;
}

.not-found-page .not-found-content .not-found-img img {
  width: 100%;
  max-width: 600px;
}

.not-found-page .not-found-content .not-found-text {
  margin-bottom: 30px;
}

.hideframe {
  width: 1px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: -1px;
}

[v-cloak] {
  display: none;
}

.smallFT {
  font-size: 20px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #2c2c2c;
}

::-webkit-scrollbar-thumb {
  background-color: #474747;
  border-radius: 4px;
}

/*# sourceMappingURL=404.97bb47db.css.map */
