@charset "UTF-8";

/*********************************************************************************************
** Table of Contents:                                                                       **
**********************************************************************************************

1.  Color Codes
    ------------------------------
    1.  Primary Color
    2.  Secondary Color
    3.  Light Color
    4.  Dark Color
    5.  Success Color
    6.  Danger Color
    7.  Warning Color
    8.  Info Color
    ------------------------------

2.  Fonts Family
    ------------------------------
    1.  Logo Font
    2.  Headings Font
    3.  Body Font
    ------------------------------

3.  Animations
    ------------------------------
    1.  Zoom Effects
    2.  Fade Effects
    3.  Spinner Effects
    4.  Scale Effects
    5.  Rotate Effects
    6.  Slide Effects
    7.  Pulse Effects
    8.  LightSpeed Effects
    9.  Flash Effect
    10. Shine Effects
    11. Floating Effect
    ------------------------------

4.  Reset & Global elements
    ------------------------------

5.  UI Elements Styles
    ------------------------------
    1.  Forms
    2.  Toggle Switch Button
    3.  Custom Tooltip
    4.  Ultimate Tooltip
    ------------------------------

6.  Components Styles
    ------------------------------
    1.  Circle Cursor
    2.  Preloader
    3.  Header
    4.  Footer
    5.  Scroll to Top Button
    6.  Ajax Loading
    7.  Toast Notifications
    ------------------------------

7.  Pages Styles
    ------------------------------
    1.  Homepage
    2.  Single Portfolio
    3.  Not Found (404)
    ------------------------------

*********************************************************************************************/
/*-----------------------------------------------------------------------------------------*/
/* 1. Color Codes */
/*-----------------------------------------------------------------------------------------*/
:root {
  /* primary color */
  --primary-h: 24;
  --primary-s: 74%;
  --primary-l: 58%;
  --primary-color: var(--primary-h), var(--primary-s), var(--primary-l);
  /* secondary color */
  --secondary-h: 13;
  --secondary-s: 96%;
  --secondary-l: 47%;
  --secondary-color: var(--secondary-h), var(--secondary-s), var(--secondary-l);
  /* light color */
  --light-h: 0;
  --light-s: 0%;
  --light-l: 100%;
  --light-color: var(--light-h), var(--light-s), var(--light-l);
  /* dark color */
  --dark-h: 0;
  --dark-s: 0%;
  --dark-l: 12%;
  --dark-color: var(--dark-h), var(--dark-s), var(--dark-l);
  /* success color */
  --success-h: 135;
  --success-s: 60%;
  --success-l: 40%;
  --success-color: var(--success-h), var(--success-s), var(--success-l);
  /* danger color */
  --danger-h: 355;
  --danger-s: 70%;
  --danger-l: 55%;
  --danger-color: var(--danger-h), var(--danger-s), var(--danger-l);
  /* warning color */
  --warning-h: 45;
  --warning-s: 100%;
  --warning-l: 50%;
  --warning-color: var(--warning-h), var(--warning-s), var(--warning-l);
  /* info color */
  --info-h: 180;
  --info-s: 80%;
  --info-l: 40%;
  --info-color: var(--info-h), var(--info-s), var(--info-l);
}

.light_theme {
  --main-color-one: var(--primary-color);
  --main-color-two: var(--secondary-color);
  --bg-color-one: var(--light-h), var(--light-s), calc(var(--light-l) - 10%);
  --bg-color-two: var(--light-color);
  --bg-color-reverse: var(--dark-color);
  --inputs-bg-color: var(--light-h), var(--light-s), calc(var(--light-l) - 7%);
  --t-color-one: var(--dark-color);
  --t-color-two: var(--dark-h), var(--dark-s), calc(var(--dark-l) + 25%);
  --border-color: var(--light-h), var(--light-s), calc(var(--light-l) - 4%);
  --card-shadow: 11px 10px 38px hsla(0, 0%, 0%, 12%);
  --drop-shadow: drop-shadow(2px 4px 6px hsla(0, 0%, 0%, 20%));
  --filter-invert-true: invert(1);
  --filter-invert-false: invert(0);
}

.dark_theme {
  --main-color-one: var(--primary-color);
  --main-color-two: var(--secondary-color);
  --bg-color-one: var(--dark-color);
  --bg-color-two: var(--dark-h), var(--dark-s), calc(var(--dark-l) + 7%);
  --bg-color-reverse: var(--light-color);
  --inputs-bg-color: var(--dark-h), var(--dark-s), calc(var(--dark-l) + 4%);
  --t-color-one: var(--light-color);
  --t-color-two: var(--dark-h), var(--dark-s), calc(var(--dark-l) + 50%);
  --border-color: var(--dark-h), var(--dark-s), calc(var(--dark-l) + 4%);
  --card-shadow: 11px 10px 38px hsla(0, 0%, 0%, 38%);
  --drop-shadow: drop-shadow(2px 4px 6px hsla(0, 0%, 0%, 20%));
  --filter-invert-true: invert(0);
  --filter-invert-false: invert(1);
}

/*-----------------------------------------------------------------------------------------*/
/* 2. Fonts Family */
/*-----------------------------------------------------------------------------------------*/
html[dir=ltr] body {
  --logo-font-family: "Saira Stencil One", sans-serif;
  --headings-font-family: "Poppins", sans-serif;
  --body-font-family: "Roboto", sans-serif;
}

html[dir=rtl] body {
  --logo-font-family: "Tajawal", sans-serif;
  --headings-font-family: "Tajawal", sans-serif;
  --body-font-family: "Tajawal", sans-serif;
}

/*-----------------------------------------------------------------------------------------*/
/* 3. Animations */
/*-----------------------------------------------------------------------------------------*/
/* 1. Zoom Effects */
/*-----------------*/
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

/* 2. Fade Effects */
/*-----------------*/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 20px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 20px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@-webkit-keyframes fadeInUpLeftTranslate {
  0% {
    opacity: 0;
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes fadeInUpLeftTranslate {
  0% {
    opacity: 0;
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes fadeInUpRightTranslate {
  0% {
    opacity: 0;
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes fadeInUpRightTranslate {
  0% {
    opacity: 0;
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes bigFadeInUp {
  0% {
    opacity: 0;
    margin-top: 40px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes bigFadeInUp {
  0% {
    opacity: 0;
    margin-top: 40px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    margin-top: -20px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    margin-top: -20px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    margin-right: -20px;
  }

  100% {
    opacity: 1;
    margin-right: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    margin-right: -20px;
  }

  100% {
    opacity: 1;
    margin-right: 0;
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    margin-left: -20px;
  }

  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    margin-left: -20px;
  }

  100% {
    opacity: 1;
    margin-left: 0;
  }
}

/* 3. Spinner Effects */
/*--------------------*/
@-webkit-keyframes spinner-left {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-left {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-right {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes spinner-right {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/* 4. scale Effects */
/*------------------*/
@-webkit-keyframes scaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes scaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/* 5. Rotate Effects */
/*-------------------*/
@-webkit-keyframes rotateInLeft {
  0% {
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInLeft {
  0% {
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInRight {
  0% {
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInRight {
  0% {
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/* 6. slide Effects */
/*------------------*/
@-webkit-keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slideDown {
  0% {
    max-height: 0;
    overflow: hidden;
  }

  100% {
    max-height: 100vh;
    overflow: unset;
  }
}

@keyframes slideDown {
  0% {
    max-height: 0;
    overflow: hidden;
  }

  100% {
    max-height: 100vh;
    overflow: unset;
  }
}

/* 7. Pulse Effect */
/*-----------------*/
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 hsla(var(--primary-color), 50%);
    box-shadow: 0 0 0 0 hsla(var(--primary-color), 50%);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px transparent;
    box-shadow: 0 0 0 10px transparent;
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 hsla(var(--primary-color), 50%);
    box-shadow: 0 0 0 0 hsla(var(--primary-color), 50%);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px transparent;
    box-shadow: 0 0 0 10px transparent;
  }
}

/* 8. LightSpeed Effects */
/*-----------------------*/
@-webkit-keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

/* 9. Flash Effect */
/*-----------------*/
@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

/* 10. Shine Effects */
/*-------------------*/
@-webkit-keyframes leftShine {
  100% {
    left: 125%;
  }
}

@keyframes leftShine {
  100% {
    left: 125%;
  }
}

@-webkit-keyframes rightShine {
  100% {
    right: 125%;
  }
}

@keyframes rightShine {
  100% {
    right: 125%;
  }
}

/* 11. Floating Effect */
/*---------------------*/
@-webkit-keyframes floating {
  0% {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}

@keyframes floating {
  0% {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}

/*------------------------------------------------------------------------------------------*/
/* 4. Defaults & Reset & Global elements */
/*------------------------------------------------------------------------------------------*/
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::-moz-selection {
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
}

::selection {
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
}

html {
  scroll-behavior: smooth;
}

html body {
  text-align: initial;
  overflow-x: hidden;
}

.hidden {
  display: none;
}

.clearfix {}

.ltr-dir {
  direction: ltr;
  display: inline-block;
}

hr {
  width: 100%;
  margin: 0;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a,
button {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: 0 !important;
  cursor: pointer;
}

button {
  background: none;
  padding: 0;
  border: none;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

/* overrides bootstrap buttons */
.btn {
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit !important;
}

input:invalid,
input:required,
textarea:invalid,
textarea:required {
  -webkit-box-shadow: none;
  box-shadow: none;
}

body .page {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section {
  outline: none;
}

.section:not(.page) {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section .section-title {
  text-align: center;
  margin-bottom: 60px;
}

.section .section-title .subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 0.6rem;
}

.section .section-title .title {
  line-height: 2.6rem;
}

.section .section-title .title:last-child {
  margin: 0;
}

.section .section-title .title:not(:last-child) {
  margin-bottom: 20px;
}

.section .section-title p {
  margin: 0;
}

@media (min-width: 992px) {
  .section .text-box-inline {
    position: relative;
  }

  html[dir=ltr] .section .text-box-inline {
    padding-left: 40px;
  }

  html[dir=rtl] .section .text-box-inline {
    padding-right: 40px;
  }
}

.section .text-box-inline>* {
  visibility: hidden;
}

.section .text-box-inline .subtitle {
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .section .text-box-inline .subtitle {
    position: absolute;
    top: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  html[dir=ltr] .section .text-box-inline .subtitle {
    left: 0;
    -webkit-transform: rotate(270deg) translateX(-100%);
    transform: rotate(270deg) translateX(-100%);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }

  html[dir=rtl] .section .text-box-inline .subtitle {
    right: 0;
    -webkit-transform: rotate(-270deg) translateX(100%);
    transform: rotate(-270deg) translateX(100%);
    -webkit-transform-origin: right top;
    transform-origin: right top;
  }

  .section .text-box-inline .subtitle:before {
    content: "";
    display: inline-block;
    height: 2px;
    width: 60px;
    background-color: hsl(var(--t-color-two));
  }

  html[dir=ltr] .section .text-box-inline .subtitle:before {
    margin-right: 20px;
  }

  html[dir=rtl] .section .text-box-inline .subtitle:before {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .section .text-box-inline .subtitle:after {
    content: "";
    display: block;
    height: 2px;
    width: 60px;
    background-color: hsl(var(--t-color-two));
    margin-top: 5px;
    margin-bottom: 16px;
  }
}

.section .text-box-inline>*:not(.subtitle):not(:last-child) {
  margin-bottom: 30px;
}

.section .text-box-inline p:last-child {
  margin-bottom: 0;
}

.section .text-box-block .subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.section .text-box-block .subtitle:before {
  content: "";
  display: inline-block;
  height: 2px;
  width: 60px;
  background-color: hsl(var(--t-color-two));
}

html[dir=ltr] .section .text-box-block .subtitle:before {
  margin-right: 20px;
}

html[dir=rtl] .section .text-box-block .subtitle:before {
  margin-left: 20px;
}

.section .text-box-block .subtitle a:hover {
  color: hsl(var(--main-color-one));
}

.section .text-box-block .subtitle a:focus {
  outline: none;
}

.enable-focus-style .section .text-box-block .subtitle a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.section .text-box-block>*:not(.subtitle):not(:last-child) {
  margin-bottom: 30px;
}

.section .text-box-block p:last-child {
  margin-bottom: 0;
}

#app-inner {
  min-height: 100vh;
  width: 100%;
  background-color: hsl(var(--bg-color-one));
  color: hsl(var(--t-color-two));
  font-family: var(--body-font-family);
  padding-top: 70px;
  overflow-x: hidden;
  -webkit-transition: background 0.6s ease-in-out, padding-top 0.6s ease-in-out;
  transition: background 0.6s ease-in-out, padding-top 0.6s ease-in-out;
}

html[dir=ltr] #app-inner {
  font-size: 1rem;
}

html[dir=rtl] #app-inner {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
label,
.section-title .title {
  /* for changing the active mode */
  -webkit-transition: color 0.6s ease-in-out;
  transition: color 0.6s ease-in-out;
  color: hsl(var(--t-color-one));
  font-family: var(--headings-font-family);
  font-weight: bold;
}

html[dir=rtl] h1,
html[dir=rtl] h2,
html[dir=rtl] h3,
html[dir=rtl] h4,
html[dir=rtl] h5,
html[dir=rtl] h6 {
  line-height: 1.4;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

p {
  line-height: 1.8rem;
}

@media (max-width: 767px) {
  .btns-container>* {
    width: 100%;
  }
}

@media (min-width: 768px) {
  html[dir=ltr] .btns-container>*:not(:last-of-type) {
    margin-right: 20px;
  }

  html[dir=rtl] .btns-container>*:not(:last-of-type) {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .btns-container>*:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.invert-in-light {
  -webkit-filter: var(--filter-invert-true);
  filter: var(--filter-invert-true);
}

.invert-in-dark {
  -webkit-filter: var(--filter-invert-false);
  filter: var(--filter-invert-false);
}

/*------------------------------------------------------------------------------------------*/
/* 5. UI Elements Styles */
/*------------------------------------------------------------------------------------------*/
/* 1. Forms Styles */
/*-----------------*/
.form-styled .group:not(:last-child) {
  margin-bottom: 20px;
}

.form-styled .group label {
  color: hsl(var(--t-color-one));
  font-size: 12px;
}

.form-styled .group label.required-field:after {
  content: "*";
  color: hsl(var(--danger-color));
  font-family: monospace;
  font-size: 16px;
  line-height: 1;
}

html[dir=ltr] .form-styled .group label.required-field:after {
  margin-left: 4px;
}

html[dir=rtl] .form-styled .group label.required-field:after {
  margin-right: 4px;
}

.form-styled .group label .optional {
  color: hsl(var(--t-color-two));
  font-style: italic;
  font-weight: normal;
  font-family: var(--body-font-family);
}

.form-styled .group .control {
  position: relative;
}

html[dir=ltr] .form-styled .group .control.has-prefix-icon input:not([type=radio]):not([type=checkbox]):not([type=file]) {
  padding-left: 40px;
}

html[dir=rtl] .form-styled .group .control.has-prefix-icon input:not([type=radio]):not([type=checkbox]):not([type=file]) {
  padding-right: 40px;
}

.form-styled .group .control.has-prefix-icon textarea {
  text-indent: 26px;
}

.form-styled .group .control.has-prefix-icon .prefix-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

html[dir=ltr] .form-styled .group .control.has-prefix-icon .prefix-icon {
  left: 0;
}

html[dir=rtl] .form-styled .group .control.has-prefix-icon .prefix-icon {
  right: 0;
}

.form-styled .group .control.has-prefix-icon textarea+.prefix-icon {
  top: 11px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]),
.form-styled .group .control textarea {
  display: block;
  width: 100%;
  height: 40px;
  background-color: hsl(var(--inputs-bg-color));
  padding-left: 14px;
  padding-right: 14px;
  border: 1px solid;
  outline: 0;
  border-radius: 0.63rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]):not(:focus),
.form-styled .group .control textarea:not(:focus) {
  border-color: hsl(var(--inputs-bg-color));
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]):focus,
.form-styled .group .control textarea:focus {
  color: hsl(var(--main-color-one));
  border-color: hsl(var(--main-color-one));
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]):focus,
.form-styled .group .control textarea:focus {
  outline: none;
}

.enable-focus-style .form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]):focus,
.enable-focus-style .form-styled .group .control textarea:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]):focus~.fa,
.form-styled .group .control textarea:focus~.fa {
  color: hsl(var(--main-color-one));
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]):disabled,
.form-styled .group .control textarea:disabled {
  background-color: hsl(var(--bg-color-two));
  cursor: not-allowed;
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]):disabled~.fa,
.form-styled .group .control textarea:disabled~.fa {
  cursor: not-allowed;
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]).invalid,
.form-styled .group .control textarea.invalid {
  color: hsl(var(--danger-color)) !important;
  border-color: hsl(var(--danger-color)) !important;
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]).invalid~.fa,
.form-styled .group .control textarea.invalid~.fa {
  color: hsl(var(--danger-color)) !important;
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]).valid,
.form-styled .group .control textarea.valid {
  color: hsl(var(--success-color)) !important;
  border-color: hsl(var(--success-color)) !important;
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]).valid~.fa,
.form-styled .group .control textarea.valid~.fa {
  color: hsl(var(--success-color)) !important;
}

.form-styled .group .control input:not([type=radio]):not([type=checkbox]):not([type=file]) {
  line-height: 1;
}

.form-styled .group .control input[type=tel] {
  direction: ltr;
}

html[dir=rtl] .form-styled .group .control input[type=tel]::-webkit-input-placeholder {
  text-align: end;
}

html[dir=rtl] .form-styled .group .control input[type=tel]::-moz-placeholder {
  text-align: end;
}

html[dir=rtl] .form-styled .group .control input[type=tel]:-ms-input-placeholder {
  text-align: end;
}

html[dir=rtl] .form-styled .group .control input[type=tel]::-ms-input-placeholder {
  text-align: end;
}

html[dir=rtl] .form-styled .group .control input[type=tel]::placeholder {
  text-align: end;
}

.form-styled .group .control textarea {
  min-height: 140px;
  white-space: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  resize: vertical;
}

.form-styled .group .control>.fa:not(.prefix-icon) {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
}

.form-styled .group .control>.fa:not(.prefix-icon).action-icon {
  cursor: pointer;
}

html[dir=ltr] .form-styled .group .control>.fa:not(.prefix-icon).action-icon {
  right: 18px;
}

html[dir=rtl] .form-styled .group .control>.fa:not(.prefix-icon).action-icon {
  left: 18px;
}

.form-styled .group .control .errors-msgs {
  display: none;
}

/* 2. Toggle Switch Button Styles */
/*--------------------------------*/
.toggle-switch-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 46px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: hsl(var(--bg-color-two));
  padding: 4px;
  border: 1px solid hsl(var(--border-color));
  border-radius: 30px;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
}

.toggle-switch-btn label {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
  height: 100%;
  color: hsl(var(--t-color-one));
  font-weight: bold;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 30px;
  cursor: pointer;
  -webkit-transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.toggle-switch-btn .switcher-toggle {
  position: absolute;
  z-index: 0;
  top: 4px;
  height: calc(46px - (4px * 2) - 2px);
  background-color: hsl(var(--main-color-one));
  border-radius: 30px;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

html[dir=ltr] .toggle-switch-btn .switcher-toggle {
  left: 4px;
}

html[dir=rtl] .toggle-switch-btn .switcher-toggle {
  right: 4px;
}

.toggle-switch-btn input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.toggle-switch-btn input:checked+label {
  color: hsl(var(--light-color));
}

html[dir=ltr] .toggle-switch-btn input:nth-of-type(2):checked~.switcher-toggle {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

html[dir=rtl] .toggle-switch-btn input:nth-of-type(2):checked~.switcher-toggle {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

html[dir=ltr] .toggle-switch-btn input:nth-of-type(3):checked~.switcher-toggle {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}

html[dir=rtl] .toggle-switch-btn input:nth-of-type(3):checked~.switcher-toggle {
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
}

.toggle-switch-btn input:focus+label {
  outline: none;
}

.enable-focus-style .toggle-switch-btn input:focus+label {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--bg-color-reverse), 70%);
  box-shadow: 0 0 0 3px hsla(var(--bg-color-reverse), 70%);
}

/* 3. Custom Tooltip Styles */
/*--------------------------*/
.has-tooltip {
  position: relative;
}

.has-tooltip .custom-tooltip {
  position: absolute;
  z-index: 1;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.8rem 1rem;
  border-radius: 0.63rem;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  -webkit-filter: var(--drop-shadow);
  filter: var(--drop-shadow);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.has-tooltip .custom-tooltip:after {
  content: "";
  position: absolute;
  background-color: hsl(var(--main-color-one));
}

.has-tooltip .custom-tooltip.custom-tooltip-top {
  top: 0;
}

html[dir=ltr] .has-tooltip .custom-tooltip.custom-tooltip-top {
  left: 50%;
  -webkit-transform: translate(-50%, -190%);
  transform: translate(-50%, -190%);
}

html[dir=rtl] .has-tooltip .custom-tooltip.custom-tooltip-top {
  right: 50%;
  -webkit-transform: translate(50%, -190%);
  transform: translate(50%, -190%);
}

.has-tooltip .custom-tooltip.custom-tooltip-top:after {
  bottom: 1px;
  height: 10px;
  width: 15px;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

html[dir=ltr] .has-tooltip .custom-tooltip.custom-tooltip-top:after {
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}

html[dir=rtl] .has-tooltip .custom-tooltip.custom-tooltip-top:after {
  right: 50%;
  -webkit-transform: translate(50%, 100%);
  transform: translate(50%, 100%);
}

.has-tooltip .custom-tooltip.custom-tooltip-bottom {
  bottom: 0;
}

html[dir=ltr] .has-tooltip .custom-tooltip.custom-tooltip-bottom {
  left: 50%;
  -webkit-transform: translate(-50%, 190%);
  transform: translate(-50%, 190%);
}

html[dir=rtl] .has-tooltip .custom-tooltip.custom-tooltip-bottom {
  right: 50%;
  -webkit-transform: translate(50%, 190%);
  transform: translate(50%, 190%);
}

.has-tooltip .custom-tooltip.custom-tooltip-bottom:after {
  top: 1px;
  height: 10px;
  width: 15px;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

html[dir=ltr] .has-tooltip .custom-tooltip.custom-tooltip-bottom:after {
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

html[dir=rtl] .has-tooltip .custom-tooltip.custom-tooltip-bottom:after {
  right: 50%;
  -webkit-transform: translate(50%, -100%);
  transform: translate(50%, -100%);
}

.has-tooltip .custom-tooltip.custom-tooltip-left {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

html[dir=ltr] .has-tooltip .custom-tooltip.custom-tooltip-left {
  right: calc(100% + 4rem);
}

html[dir=rtl] .has-tooltip .custom-tooltip.custom-tooltip-left {
  left: calc(100% + 4rem);
}

.has-tooltip .custom-tooltip.custom-tooltip-left:after {
  top: 50%;
  height: 15px;
  width: 10px;
}

html[dir=ltr] .has-tooltip .custom-tooltip.custom-tooltip-left:after {
  right: 1px;
  -webkit-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
  -webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
  clip-path: polygon(100% 50%, 0 0, 0 100%);
}

html[dir=rtl] .has-tooltip .custom-tooltip.custom-tooltip-left:after {
  left: 1px;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%);
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
}

.has-tooltip .custom-tooltip.custom-tooltip-right {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

html[dir=ltr] .has-tooltip .custom-tooltip.custom-tooltip-right {
  left: calc(100% + 4rem);
}

html[dir=rtl] .has-tooltip .custom-tooltip.custom-tooltip-right {
  right: calc(100% + 4rem);
}

.has-tooltip .custom-tooltip.custom-tooltip-right:after {
  top: 50%;
  height: 15px;
  width: 10px;
}

html[dir=ltr] .has-tooltip .custom-tooltip.custom-tooltip-right:after {
  left: 1px;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%);
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
}

html[dir=rtl] .has-tooltip .custom-tooltip.custom-tooltip-right:after {
  right: 1px;
  -webkit-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
  -webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
  clip-path: polygon(100% 50%, 0 0, 0 100%);
}

.has-tooltip:hover,
.enable-focus-style .has-tooltip:focus {
  z-index: 1;
}

.has-tooltip:hover .custom-tooltip,
.enable-focus-style .has-tooltip:focus .custom-tooltip {
  visibility: visible;
  opacity: 1;
}

html[dir=ltr] .has-tooltip:hover .custom-tooltip-top,
html[dir=ltr] .enable-focus-style .has-tooltip:focus .custom-tooltip-top {
  -webkit-transform: translate(-50%, -150%);
  transform: translate(-50%, -150%);
}

html[dir=rtl] .has-tooltip:hover .custom-tooltip-top,
html[dir=rtl] .enable-focus-style .has-tooltip:focus .custom-tooltip-top {
  -webkit-transform: translate(50%, -150%);
  transform: translate(50%, -150%);
}

html[dir=ltr] .has-tooltip:hover .custom-tooltip-bottom,
html[dir=ltr] .enable-focus-style .has-tooltip:focus .custom-tooltip-bottom {
  -webkit-transform: translate(-50%, 150%);
  transform: translate(-50%, 150%);
}

html[dir=rtl] .has-tooltip:hover .custom-tooltip-bottom,
html[dir=rtl] .enable-focus-style .has-tooltip:focus .custom-tooltip-bottom {
  -webkit-transform: translate(50%, 150%);
  transform: translate(50%, 150%);
}

html[dir=ltr] .has-tooltip:hover .custom-tooltip-left,
html[dir=ltr] .enable-focus-style .has-tooltip:focus .custom-tooltip-left {
  right: calc(100% + 2rem);
}

html[dir=rtl] .has-tooltip:hover .custom-tooltip-left,
html[dir=rtl] .enable-focus-style .has-tooltip:focus .custom-tooltip-left {
  left: calc(100% + 2rem);
}

html[dir=ltr] .has-tooltip:hover .custom-tooltip-right,
html[dir=ltr] .enable-focus-style .has-tooltip:focus .custom-tooltip-right {
  left: calc(100% + 2rem);
}

html[dir=rtl] .has-tooltip:hover .custom-tooltip-right,
html[dir=rtl] .enable-focus-style .has-tooltip:focus .custom-tooltip-right {
  right: calc(100% + 2rem);
}

/* 4. Ultimate Tooltip Styles */
/*----------------------------*/
.has-ultimate-tooltip .ultimate-tooltip {
  background-color: hsl(var(--bg-color-two));
  text-align: center;
  padding: 0.8rem 1rem;
  border-radius: 0.63rem;
  -webkit-filter: var(--drop-shadow);
  filter: var(--drop-shadow);
}

@media (min-width: 577px) {
  .has-ultimate-tooltip .ultimate-tooltip {
    width: 300px;
  }
}

@media (max-width: 576px) {
  .has-ultimate-tooltip .ultimate-tooltip {
    width: 85vw;
  }
}

.has-ultimate-tooltip .ultimate-tooltip p {
  color: hsl(var(--t-color-two));
  line-height: 1.7rem;
  font-size: 1.1rem;
}

.has-ultimate-tooltip .ultimate-tooltip p:first-child {
  margin: 0 0 20px;
}

.has-ultimate-tooltip .ultimate-tooltip p:last-child {
  margin: 20px 0 0;
}

.has-ultimate-tooltip .ultimate-tooltip .ultimate-tooltip-title {
  color: hsl(var(--main-color-one));
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.has-ultimate-tooltip .ultimate-tooltip .ultimate-tooltip-subtitle {
  font-size: 0.95rem;
  margin: 0;
}

.has-ultimate-tooltip .ultimate-tooltip[data-popper-placement=top]+.ultimate-tooltip-arrow {
  top: -21px;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

html[dir=ltr] .has-ultimate-tooltip .ultimate-tooltip[data-popper-placement=top]+.ultimate-tooltip-arrow {
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

html[dir=rtl] .has-ultimate-tooltip .ultimate-tooltip[data-popper-placement=top]+.ultimate-tooltip-arrow {
  -webkit-transform: translate(50%, -100%);
  transform: translate(50%, -100%);
}

.has-ultimate-tooltip .ultimate-tooltip[data-popper-placement=bottom]+.ultimate-tooltip-arrow {
  bottom: -21px;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

html[dir=ltr] .has-ultimate-tooltip .ultimate-tooltip[data-popper-placement=bottom]+.ultimate-tooltip-arrow {
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}

html[dir=rtl] .has-ultimate-tooltip .ultimate-tooltip[data-popper-placement=bottom]+.ultimate-tooltip-arrow {
  -webkit-transform: translate(50%, 100%);
  transform: translate(50%, 100%);
}

.has-ultimate-tooltip .ultimate-tooltip-arrow {
  position: absolute;
  height: 10px;
  width: 15px;
  background-color: hsl(var(--bg-color-two));
}

html[dir=ltr] .has-ultimate-tooltip .ultimate-tooltip-arrow {
  left: 50%;
}

html[dir=rtl] .has-ultimate-tooltip .ultimate-tooltip-arrow {
  right: 50%;
}

.has-ultimate-tooltip .ultimate-tooltip,
.has-ultimate-tooltip .ultimate-tooltip-arrow {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
}

.has-ultimate-tooltip:hover,
.enable-focus-style .has-ultimate-tooltip:focus {
  z-index: 1;
}

.has-ultimate-tooltip:hover .ultimate-tooltip,
.has-ultimate-tooltip:hover .ultimate-tooltip-arrow,
.enable-focus-style .has-ultimate-tooltip:focus .ultimate-tooltip,
.enable-focus-style .has-ultimate-tooltip:focus .ultimate-tooltip-arrow {
  visibility: visible;
  opacity: 1;
}

/*------------------------------------------------------------------------------------------*/
/* 6. Components Styles */
/*------------------------------------------------------------------------------------------*/
/* 1. Circle Cursor Styles */
/*-------------------------*/
.circle-cursor {
  position: fixed;
  z-index: 99999999;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  mix-blend-mode: difference;
  pointer-events: none;
  visibility: hidden;
}

.circle-cursor.circle-cursor-outer {
  height: 30px;
  width: 30px;
  border: 1px solid hsl(var(--main-color-one));
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}

.circle-cursor.circle-cursor-inner {
  height: 6px;
  width: 6px;
  background-color: hsl(var(--main-color-one));
  -webkit-transition: top 0.02s ease-out, left 0.02s ease-out, height 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: top 0.02s ease-out, left 0.02s ease-out, height 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.circle-cursor.circle-cursor-inner.cursor-link-hover {
  height: 20px;
  width: 20px;
  opacity: 0.5;
}

.circle-cursor.circle-cursor-inner.cursor-click-effect {
  height: 13px;
  width: 13px;
}

/* 2. Preloader Styles */
/*---------------------*/
.preloader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 4000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: hsl(var(--bg-color-two));
}

.preloader .preloader-content {
  margin: auto;
}

.preloader .preloader-content .logo {
  text-align: center;
  margin-bottom: 25px;
}

.preloader .preloader-content .logo svg {
  width: 36px;
}

.preloader .preloader-content .logo svg path {
  fill: hsl(var(--bg-color-reverse));
}

.preloader .preloader-content .loading-bar {
  height: 4px;
  width: 180px;
  background-color: hsl(var(--bg-color-one));
  border-radius: 4px;
  overflow: hidden;
}

.preloader .preloader-content .loading-bar .loading-bar-progress {
  display: block;
  height: 100%;
  width: var(--loading-percentage, 0%);
  background-color: hsl(var(--bg-color-reverse));
}

.preloader~.hide-in-preloading {
  visibility: hidden;
}

/* 3. Header Styles */
/*------------------*/
header {
  position: fixed;
  z-index: 2000;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header>.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .logo {
  position: relative;
  z-index: 2;
}

header .logo h1 {
  color: hsl(var(--bg-color-reverse));
  font-family: var(--logo-font-family);
  font-size: 2rem;
  margin: 0;
}

html[dir=ltr] header .logo h1 {
  font-weight: normal;
}

header .logo h1:after {
  content: "";
  display: inline-block;
  height: 0.17em;
  width: 0.2em;
  background-color: hsl(var(--main-color-one));
}

html[dir=ltr] header .logo h1:after {
  margin-left: 0.08em;
}

html[dir=rtl] header .logo h1:after {
  margin-right: 0.08em;
}

header .logo h1:focus-within {
  outline: none;
}

.enable-focus-style header .logo h1:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {
  header nav .nav-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (max-width: 991px) {
  header nav .nav-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    text-align: center;
    padding: 100px 40px;
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  html[dir=ltr] header nav .nav-links li:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  html[dir=rtl] header nav .nav-links li:not(:last-of-type) {
    margin-left: 1.6rem;
  }
}

@media (max-width: 991px) {
  header nav .nav-links li {
    width: 100%;
    overflow: hidden;
  }

  header nav .nav-links li:not(:last-of-type) {
    margin-bottom: 0.8rem;
  }
}

header nav .nav-links li a {
  color: hsl(var(--t-color-one));
  font-family: var(--headings-font-family);
  font-weight: bold;
  padding: 0.4rem 0.2rem;
}

header nav .nav-links li a {
  position: relative;
}

header nav .nav-links li a:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 0.15rem;
  width: 0;
  background-color: hsl(var(--main-color-one));
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

html[dir=ltr] header nav .nav-links li a:before {
  left: 0;
}

html[dir=rtl] header nav .nav-links li a:before {
  right: 0;
}

header nav .nav-links li a:hover:before {
  width: 100%;
}

@media (min-width: 992px) {
  header nav .nav-links li a {
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  header nav .nav-links li a {
    display: inline-block;
    font-size: 2rem;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 2);
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 2);
  }
}

header nav .nav-links li a:after {
  /*content: "";*/
  display: inline-block;
  height: 0.2em;
  width: 0.2em;
  background-color: hsl(var(--t-color-one));
  border-radius: 50%;
}

html[dir=ltr] header nav .nav-links li a:after {
  margin-left: 0.1em;
}

html[dir=rtl] header nav .nav-links li a:after {
  margin-right: 0.1em;
}

header nav .nav-links li a:focus {
  outline: none;
}

.enable-focus-style header nav .nav-links li a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

header nav:before {
  content: "";
  position: fixed;
  z-index: 1;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: hsl(var(--bg-color-two));
}

header nav .desktop-menu-content {
  position: fixed;
  z-index: 2;
  left: 0;
  height: 100vh;
  width: 100vw;
  outline: none;
}

@media (max-width: 991px) {
  header nav .desktop-menu-content {
    display: none;
  }
}

header nav .desktop-menu-content .container {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  padding-top: 80px;
}

header nav .desktop-menu-content .container>* {
  width: 100%;
}

header nav .desktop-menu-content .about-us p {
  color: hsl(var(--t-color-one));
}

html[dir=ltr] header nav .desktop-menu-content .about-us p {
  font-size: 1rem;
}

html[dir=rtl] header nav .desktop-menu-content .about-us p {
  font-size: 1.1rem;
}

header nav .desktop-menu-content .about-us .block-title {
  font-size: 2.8rem;
  line-height: 4rem;
}

header nav .desktop-menu-content .contact-info li .block-title {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

header nav .desktop-menu-content .contact-info li .block-title:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: hsl(var(--main-color-one));
}

header nav .desktop-menu-content .contact-info a {
  position: relative;
}

header nav .desktop-menu-content .contact-info a:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 0.1rem;
  width: 0;
  background-color: hsl(var(--main-color-one));
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

html[dir=ltr] header nav .desktop-menu-content .contact-info a:before {
  left: 0;
}

html[dir=rtl] header nav .desktop-menu-content .contact-info a:before {
  right: 0;
}

header nav .desktop-menu-content .contact-info a:hover:before {
  width: 100%;
}

header nav .desktop-menu-content .contact-info a:focus {
  outline: none;
}

.enable-focus-style header nav .desktop-menu-content .contact-info a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {

  header nav:not(.menu-open):before,
  header nav:not(.menu-open) .desktop-menu-content {
    -webkit-transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99) 0.6s;
    transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99) 0.6s;
    top: 100vh;
  }
}

@media (max-width: 991px) {

  header nav:not(.menu-open):before,
  header nav:not(.menu-open) .desktop-menu-content,
  header nav:not(.menu-open) .nav-links {
    -webkit-transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99) 0.4s;
    transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99) 0.4s;
    top: 100vh;
  }

  header nav:not(.menu-open) .nav-links li a {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}

header nav:not(.menu-open) .desktop-menu-content>* {
  visibility: hidden;
}

header nav:not(.menu-open) .desktop-menu-content .about-us p {
  -webkit-transition: all 0.2s ease-in-out 0.4s;
  transition: all 0.2s ease-in-out 0.4s;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

header nav:not(.menu-open) .desktop-menu-content .about-us .block-title {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

header nav:not(.menu-open) .desktop-menu-content .contact-info .block-title {
  -webkit-transition: color 0s linear 0.2s;
  transition: color 0s linear 0.2s;
  color: transparent;
}

header nav:not(.menu-open) .desktop-menu-content .contact-info .block-title:after {
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
}

html[dir=ltr] header nav:not(.menu-open) .desktop-menu-content .contact-info .block-title:after {
  -webkit-transition-property: left;
  transition-property: left;
  left: -100%;
}

html[dir=rtl] header nav:not(.menu-open) .desktop-menu-content .contact-info .block-title:after {
  -webkit-transition-property: right;
  transition-property: right;
  right: -100%;
}

header nav:not(.menu-open) .desktop-menu-content .contact-info ul {
  -webkit-transition: all 0.2s ease-in-out 0.4s;
  transition: all 0.2s ease-in-out 0.4s;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
  opacity: 0;
}

header nav.menu-open:before,
header nav.menu-open .desktop-menu-content {
  -webkit-transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  top: 0;
}

@media (min-width: 992px) {

  header nav.menu-open .nav-links,
  header nav.menu-open~.options-icons .lang-switcher {
    visibility: hidden;
  }

  header nav.menu-open~.options-icons .lang-switcher {
    z-index: 0;
    opacity: 0;
  }
}

@media (max-width: 991px) {
  header nav.menu-open .nav-links {
    -webkit-transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
    transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
    top: 0;
  }

  header nav.menu-open .nav-links li a {
    -webkit-transition-delay: 0.8s;
    transition-delay: 0.8s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

header nav.menu-open .desktop-menu-content>* {
  visibility: visible;
}

header nav.menu-open .desktop-menu-content .about-us p {
  -webkit-transition: all 0.2s ease-in-out 1s;
  transition: all 0.2s ease-in-out 1s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

header nav.menu-open .desktop-menu-content .about-us .block-title {
  -webkit-transition: all 0.4s ease-in-out 1.2s;
  transition: all 0.4s ease-in-out 1.2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

header nav.menu-open .desktop-menu-content .contact-info .block-title {
  -webkit-transition: color 0s linear 1.2s;
  transition: color 0s linear 1.2s;
}

header nav.menu-open .desktop-menu-content .contact-info .block-title:after {
  -webkit-transition: 0.4s linear 1s;
  transition: 0.4s linear 1s;
}

html[dir=ltr] header nav.menu-open .desktop-menu-content .contact-info .block-title:after {
  left: 100%;
  -webkit-transition-property: left;
  transition-property: left;
}

html[dir=rtl] header nav.menu-open .desktop-menu-content .contact-info .block-title:after {
  right: 100%;
  -webkit-transition-property: right;
  transition-property: right;
}

header nav.menu-open .desktop-menu-content .contact-info ul {
  -webkit-transition: all 0.2s ease-in-out 1.4s;
  transition: all 0.2s ease-in-out 1.4s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

header .options-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

html[dir=ltr] header .options-icons>li:not(:last-of-type) {
  margin-right: 1rem;
}

html[dir=rtl] header .options-icons>li:not(:last-of-type) {
  margin-left: 1rem;
}

header .options-icons .lang-switcher {
  -webkit-transition: opacity 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  transition: opacity 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
}

header .options-icons .lang-switcher .lang-switcher-menu {
  position: relative;
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher .lang-switcher-menu {
    z-index: 1;
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher .lang-switcher-menu {
    z-index: 0;
  }
}

header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children>a:after {
  content: "\f107";
  font-family: FontAwesome;
}

html[dir=ltr] header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children>a:after {
  margin-left: 8px;
}

html[dir=rtl] header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children>a:after {
  margin-right: 8px;
}

header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children>a:focus {
  outline: none;
}

.enable-focus-style header .options-icons .lang-switcher .lang-switcher-menu .menu-item-has-children>a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
  background-color: hsl(var(--bg-color-two));
  z-index: -1;
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    position: absolute;
    top: calc(100% + 7px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    padding: 6px 0;
    border-radius: 0.63rem;
    -webkit-filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }

  html[dir=ltr] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    left: 50%;
    -webkit-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
  }

  html[dir=rtl] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    right: 50%;
    -webkit-transform: translate(50%, 20px);
    transform: translate(50%, 20px);
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before,
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
  }

  html[dir=ltr] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before,
  html[dir=ltr] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    left: 50%;
  }

  html[dir=rtl] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before,
  html[dir=rtl] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    right: 50%;
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before {
    top: -25px;
    height: 35px;
    width: 100%;
  }

  html[dir=ltr] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  html[dir=rtl] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:before {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    top: -5px;
    height: 6px;
    width: 12px;
    background-color: inherit;
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  html[dir=ltr] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  html[dir=rtl] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu:after {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    width: 100%;
    border-top: 2px solid hsl(var(--main-color-one));
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }

  html[dir=ltr] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }

  html[dir=rtl] header .options-icons .lang-switcher .lang-switcher-menu .sub-menu {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:first-of-type:not(:only-of-type) {
    border-radius: 0.63rem 0.63rem 0 0;
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:last-of-type:not(:only-of-type) {
    border-radius: 0 0 0.63rem 0.63rem;
  }

  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:only-of-type {
    border-radius: 0.63rem;
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:not(:last-of-type) {
    border-bottom: 1px solid hsl(var(--bg-color-one));
  }
}

header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item a {
  display: block;
  font-weight: bold;
  padding: 0 14px;
}

@media (min-width: 992px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item a {
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
  }
}

@media (max-width: 991px) {
  header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item a {
    height: 40px;
    line-height: 40px;
  }
}

header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item a:hover {
  color: hsl(var(--main-color-one));
}

header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:focus-within {
  outline: none;
}

.enable-focus-style header .options-icons .lang-switcher .lang-switcher-menu .sub-menu .menu-item:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {

  header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu,
  header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    visibility: visible;
    opacity: 1;
  }

  html[dir=ltr] header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu,
  html[dir=ltr] header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  html[dir=rtl] header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu,
  html[dir=rtl] header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
  }
}

@media (max-width: 991px) {

  header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu,
  header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    visibility: visible;
    opacity: 1;
  }

  html[dir=ltr] header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu,
  html[dir=ltr] header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  html[dir=rtl] header .options-icons .lang-switcher:hover .lang-switcher-menu .sub-menu,
  html[dir=rtl] header .options-icons .lang-switcher:focus-within .lang-switcher-menu .sub-menu {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

header .options-icons .theme-switcher button {
  position: relative;
  height: 28px;
  width: 48px;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .options-icons .theme-switcher button:after {
  content: "";
  position: absolute;
  top: 3px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

header .options-icons .theme-switcher button.light_theme:after {
  left: 23px;
  -webkit-box-shadow: 22px -20px #f9d71c inset;
  box-shadow: 22px -20px #f9d71c inset;
}

header .options-icons .theme-switcher button.dark_theme:after {
  left: 3px;
  -webkit-box-shadow: 9px -7px #fff inset;
  box-shadow: 9px -7px #fff inset;
}

header .options-icons .theme-switcher button:focus {
  outline: none;
}

.enable-focus-style header .options-icons .theme-switcher button:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

header .options-icons .hamburger-btn {
  position: relative;
  z-index: 2;
}

header .options-icons .hamburger-btn button {
  position: relative;
  height: 20px;
  width: 16px;
  margin-bottom: 4px;
}

header .options-icons .hamburger-btn span {
  display: block;
  position: absolute;
  width: 2px;
  background-color: hsl(var(--bg-color-reverse));
  border-radius: 5px;
  -webkit-transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

header .options-icons .hamburger-btn span:nth-of-type(1) {
  bottom: 50%;
  height: 50%;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

html[dir=ltr] header .options-icons .hamburger-btn span:nth-of-type(1) {
  left: 0;
}

html[dir=rtl] header .options-icons .hamburger-btn span:nth-of-type(1) {
  right: 0;
}

header .options-icons .hamburger-btn span:nth-of-type(2) {
  top: 0;
  height: 100%;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

html[dir=ltr] header .options-icons .hamburger-btn span:nth-of-type(2) {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

html[dir=rtl] header .options-icons .hamburger-btn span:nth-of-type(2) {
  right: 50%;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

header .options-icons .hamburger-btn span:nth-of-type(3) {
  top: 50%;
  height: 50%;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

html[dir=ltr] header .options-icons .hamburger-btn span:nth-of-type(3) {
  right: 0;
}

html[dir=rtl] header .options-icons .hamburger-btn span:nth-of-type(3) {
  left: 0;
}

html[dir=ltr] header .options-icons .hamburger-btn:not(.open) button {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}

html[dir=rtl] header .options-icons .hamburger-btn:not(.open) button {
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}

html[dir=ltr] header .options-icons .hamburger-btn.open button {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

html[dir=rtl] header .options-icons .hamburger-btn.open button {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

html[dir=ltr] header .options-icons .hamburger-btn.open span:nth-of-type(1) {
  -webkit-transform: rotate(90deg) translateY(3px);
  transform: rotate(90deg) translateY(3px);
}

html[dir=rtl] header .options-icons .hamburger-btn.open span:nth-of-type(1) {
  -webkit-transform: rotate(-90deg) translateY(3px);
  transform: rotate(-90deg) translateY(3px);
}

html[dir=ltr] header .options-icons .hamburger-btn.open span:nth-of-type(3) {
  -webkit-transform: rotate(90deg) translateY(-3px);
  transform: rotate(90deg) translateY(-3px);
}

html[dir=rtl] header .options-icons .hamburger-btn.open span:nth-of-type(3) {
  -webkit-transform: rotate(-90deg) translateY(-3px);
  transform: rotate(-90deg) translateY(-3px);
}

header .options-icons .hamburger-btn span {
  -webkit-transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  transition: all 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
}

header .options-icons .hamburger-btn:focus-within {
  outline: none;
}

.enable-focus-style header .options-icons .hamburger-btn:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {
  header.big-header .theme-switcher button {
    background-color: hsl(var(--bg-color-two));
  }

  header.small-header {
    background-color: hsl(var(--bg-color-two));
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);
  }

  header.small-header .container {
    height: 60px;
  }

  header.small-header .theme-switcher button {
    background-color: hsl(var(--bg-color-one));
  }
}

@media (max-width: 991px) {
  header {
    background-color: hsl(var(--bg-color-two));
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);
  }

  header .container {
    height: 60px;
  }

  header .theme-switcher button {
    background-color: hsl(var(--bg-color-one));
  }
}

header:not(.header-hidden) {
  top: 0;
}

@media (min-width: 992px) {
  header.header-hidden.big-header {
    top: -100px;
  }

  header.header-hidden.small-header {
    top: -60px;
  }
}

@media (max-width: 991px) {
  header.header-hidden {
    top: -60px;
  }
}

/* 4. Footer */
/*-----------*/
footer {
  background-color: hsl(var(--bg-color-two));
  font-size: 0.95rem;
  line-height: 1.5rem;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
}

footer .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  footer .row {
    text-align: center;
  }
}

@media (max-width: 991px) {
  footer .row>div:first-of-type {
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  footer .row>div:last-of-type {
    text-align: end;
  }
}

footer .row>div:last-of-type a {
  color: hsl(var(--main-color-one));
}

footer .row>div:last-of-type a:hover {
  text-decoration: underline;
}

footer .row>div:last-of-type a:focus {
  outline: none;
}

.enable-focus-style footer .row>div:last-of-type a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

footer .logo h1 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: hsl(var(--bg-color-reverse));
  font-family: var(--logo-font-family);
  font-size: 2rem;
  margin: 0;
}

html[dir=ltr] footer .logo h1 {
  font-weight: normal;
}

@media (max-width: 991px) {
  footer .logo h1 {
    margin-left: auto;
    margin-right: auto;
  }
}

footer .logo h1:after {
  content: "";
  display: inline-block;
  height: 0.17em;
  width: 0.2em;
  background-color: hsl(var(--main-color-one));
}

html[dir=ltr] footer .logo h1:after {
  margin-left: 0.08em;
}

html[dir=rtl] footer .logo h1:after {
  margin-right: 0.08em;
}

footer .logo h1:focus-within {
  outline: none;
}

.enable-focus-style footer .logo h1:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

/* 5. Scroll to Top Button Styles */
/*--------------------------------*/
.scroll-to-top {
  position: fixed;
  z-index: 1990;
  bottom: 25px;
  height: 46px;
  width: 46px;
  color: hsl(var(--primary-color));
  font-size: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 2px hsla(var(--primary-color), 25%) inset;
  box-shadow: 0 0 0 2px hsla(var(--primary-color), 25%) inset;
  -webkit-transition: none;
  transition: none;
  visibility: hidden;
}

html[dir=ltr] .scroll-to-top {
  right: 25px;
}

html[dir=rtl] .scroll-to-top {
  left: 25px;
}

.scroll-to-top svg path {
  fill: none;
  stroke: hsl(var(--primary-color));
  stroke-width: 5;
}

.scroll-to-top::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 40%;
  width: 25%;
  background-color: hsl(var(--primary-color));
  -webkit-clip-path: polygon(0 30%, 50% 0, 100% 30%, 94% 39%, 58% 17%, 58% 100%, 42% 100%, 42% 17%, 6% 39%);
  clip-path: polygon(0 30%, 50% 0, 100% 30%, 94% 39%, 58% 17%, 58% 100%, 42% 100%, 42% 17%, 6% 39%);
}

.scroll-to-top:focus {
  outline: none;
}

.enable-focus-style .scroll-to-top:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

/* 6. Ajax Loading Styles */
/*------------------------*/
.ajax-loading {
  position: fixed;
  z-index: 2000;
  bottom: 20px;
  background-color: hsl(var(--bg-color-two));
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  padding: 3px;
  border-radius: 50%;
}

html[dir=ltr] .ajax-loading {
  left: 20px;
}

html[dir=rtl] .ajax-loading {
  right: 20px;
}

.ajax-loading span {
  display: block;
  height: 29px;
  width: 29px;
  border-width: 3px;
  border-style: solid;
  border-color: transparent hsl(var(--main-color-one));
  border-radius: 50%;
}

html[dir=ltr] .ajax-loading span {
  -webkit-animation: spinner-left 0.6s linear infinite;
  animation: spinner-left 0.6s linear infinite;
}

html[dir=rtl] .ajax-loading span {
  -webkit-animation: spinner-right 0.6s linear infinite;
  animation: spinner-right 0.6s linear infinite;
}

/* 7. Toast Notifications Styles */
/*-------------------------------*/
@-webkit-keyframes timer {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes timer {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.notifications-container {
  position: fixed;
  z-index: 3000;
  bottom: 25px;
}

html[dir=ltr] .notifications-container {
  right: 80px;
}

html[dir=rtl] .notifications-container {
  left: 80px;
}

@media (max-width: 576px) {
  .notifications-container {
    width: calc(100% - 30px);
    max-width: 250px;
  }
}

.notifications-container .notification {
  position: relative;
  line-height: 1.5;
  color: #fff;
}

html[dir=ltr] .notifications-container .notification {
  padding: 10px 28px 10px 14px;
}

html[dir=rtl] .notifications-container .notification {
  padding: 10px 14px 10px 28px;
}

@media (min-width: 577px) {
  .notifications-container .notification {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .notifications-container .notification {
    width: 100%;
  }
}

.notifications-container .notification.timer .disappearing-time {
  display: block;
  position: absolute;
  bottom: -3px;
  height: 3px;
  background-color: inherit;
  opacity: 0.6;
  -webkit-animation-name: timer;
  animation-name: timer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

html[dir=ltr] .notifications-container .notification.timer .disappearing-time {
  left: 0;
}

html[dir=rtl] .notifications-container .notification.timer .disappearing-time {
  right: 0;
}

.notifications-container .notification.success {
  background-color: hsl(var(--success-color));
}

.notifications-container .notification.danger {
  background-color: hsl(var(--danger-color));
}

.notifications-container .notification.warning {
  background-color: hsl(var(--warning-color));
  color: hsl(var(--dark-color));
}

.notifications-container .notification.info {
  background-color: hsl(var(--info-color));
}

.notifications-container .notification:not(:last-of-type) {
  margin-bottom: 10px;
}

.notifications-container .notification .fa {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

html[dir=ltr] .notifications-container .notification .fa {
  right: 12px;
}

html[dir=rtl] .notifications-container .notification .fa {
  left: 12px;
}

.notifications-container .notification .fa:hover {
  opacity: 1;
}

.notifications-container .notify-enter-active {
  -webkit-animation: 0.3s ease-in-out;
  animation: 0.3s ease-in-out;
}

html[dir=ltr] .notifications-container .notify-enter-active {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

html[dir=rtl] .notifications-container .notify-enter-active {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.notifications-container .notify-leave-active {
  animation: 0.3s ease-in-out reverse;
}

html[dir=ltr] .notifications-container .notify-leave-active {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

html[dir=rtl] .notifications-container .notify-leave-active {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.notifications-container .notify-move {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

/*------------------------------------------------------------------------------------------*/
/* 7. Pages Styles */
/*------------------------------------------------------------------------------------------*/
/* 1. Homepage Styles */
/*--------------------*/
/* 1. Hero Section Styles */
/*------------------------*/
@media (max-width: 991px) {
  .hero-section .hero-img {
    position: relative;
    height: 80vw;
    max-height: 400px;
    width: 80vw;
    max-width: 400px;
    margin: auto;
  }
}

.hero-section .hero-img .layer {
  position: absolute;
  width: 100%;
}

@media (min-width: 992px) {
  .hero-section .hero-img .layer {
    top: 0;
    left: 0;
    -webkit-transition: -webkit-transform 1s ease;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
  }
}

@media (max-width: 991px) {
  html[dir=ltr] .hero-section .hero-img .layer {
    left: 50%;
  }

  html[dir=rtl] .hero-section .hero-img .layer {
    right: 50%;
  }
}

.hero-section .hero-img .layer:first-of-type {
  height: 100%;
  -webkit-mask-image: url("../assets/images/splash.png");
  mask-image: url("../assets/images/splash.png");
  -webkit-mask-repeat: no-repeat;
  /*mask-repeat: no-repeat;*/
  -webkit-mask-position: center;
  /*mask-position: center;*/
  -webkit-mask-size: contain;
  /*mask-size: contain;*/
}

.hero-section .hero-img .layer:first-of-type:before {
  content: "";
  position: absolute;
}

@media (min-width: 992px) {
  .hero-section .hero-img .layer:first-of-type:before {
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: -webkit-gradient(linear, left top, right top, from(hsl(var(--main-color-one))), to(hsl(var(--main-color-two))));
    background: linear-gradient(to right, hsl(var(--main-color-one)) 0%, hsl(var(--main-color-two)) 100%);
  }
}

@media (max-width: 991px) {
  .hero-section .hero-img .layer:first-of-type {
    top: 50%;
  }

  html[dir=ltr] .hero-section .hero-img .layer:first-of-type {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  html[dir=rtl] .hero-section .hero-img .layer:first-of-type {
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
  }

  .hero-section .hero-img .layer:first-of-type:before {
    bottom: 0;
    height: 80%;
    width: 80%;
    background-color: hsl(var(--main-color-one));
    border-radius: 50%;
  }

  html[dir=ltr] .hero-section .hero-img .layer:first-of-type:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  html[dir=rtl] .hero-section .hero-img .layer:first-of-type:before {
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

.hero-section .hero-img .layer:last-of-type {
  height: 50%;
  overflow: hidden;
}

@media (max-width: 991px) {
  .hero-section .hero-img .layer:last-of-type {
    top: 0;
  }

  html[dir=ltr] .hero-section .hero-img .layer:last-of-type {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  html[dir=rtl] .hero-section .hero-img .layer:last-of-type {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  .hero-section .hero-img .layer:last-of-type img {
    height: 200%;
    width: 200%;
  }

  html[dir=ltr] .hero-section .hero-img .layer:last-of-type img {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }

  html[dir=rtl] .hero-section .hero-img .layer:last-of-type img {
    -webkit-transform: translateX(25%);
    transform: translateX(25%);
  }
}

.hero-section .hero-img .layer img {
  display: block;
  -webkit-filter: drop-shadow(2px 4px 6px black);
  filter: drop-shadow(2px 4px 6px black);
}

@media (min-width: 992px) {
  .hero-section .hero-img .layer img {
    position: relative;
    z-index: 2;
    height: calc(95vh - 70px);
    max-width: 100%;
    margin: auto;
    margin-top: 3rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: translateX(-8rem); 
  }
}

@media (max-width: 991px) {
  .hero-section .hero-img .layer img {
    height: 100%;
    width: 75%;
    -o-object-fit: contain;
    object-fit: contain;
    margin-left: 25px;
  }
}

.hero-section .hero-text {
  text-align: center;
}

@media (min-width: 992px) {
  .hero-section .hero-text {
    position: absolute;
    z-index: 3;
    top: 45%;
    width: 90%;
    text-shadow: 0px 0px 20px hsl(var(--dark-color));
  }

  html[dir=ltr] .hero-section .hero-text {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  html[dir=rtl] .hero-section .hero-text {
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@media (max-width: 991px) {
  .hero-section .hero-text {
    width: 90vw;
  }
}

.hero-section .hero-text h2 {
  margin-bottom: 30px;
}

html[dir=ltr] .hero-section .hero-text h2 {
  text-transform: capitalize;
}

@media (min-width: 992px) {
  .hero-section .hero-text h2 {
    color: hsl(var(--light-color)) !important;
    font-size: 3rem;
  }
}

@media (max-width: 991px) {
  .hero-section .hero-text h2 {
    color: hsl(var(--bg-color-reverse));
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .hero-section .hero-text .btn {
    color: hsl(var(--light-color));
    border-color: hsl(var(--light-color));
  }

  .hero-section .hero-text .btn:hover {
    background-color: hsl(var(--light-color));
    color: hsl(var(--dark-color));
    text-shadow: none;
  }
}

@media (max-width: 991px) {
  .hero-section .hero-text .btn {
    color: hsl(var(--bg-color-reverse));
    border-color: hsl(var(--bg-color-reverse));
  }

  .hero-section .hero-text .btn:hover {
    background-color: hsl(var(--bg-color-reverse));
    color: hsl(var(--bg-color-one));
  }
}

.hero-section .hero-text .btn:focus {
  outline: none;
}

.enable-focus-style .hero-section .hero-text .btn:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.hero-section .social,
.hero-section .scroll-down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  font-size: 18px;
  text-transform: uppercase;
}

@media (max-width: 991px) {

  .hero-section .social,
  .hero-section .scroll-down {
    display: none;
  }
}

.hero-section .social.social:before,
.hero-section .social.scroll-down:after,
.hero-section .scroll-down.social:before,
.hero-section .scroll-down.scroll-down:after {
  content: "";
  display: inline-block;
  height: 2px;
  width: 30px;
  background-color: hsl(var(--t-color-two));
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hero-section .social.social a:hover,
.hero-section .social.scroll-down:hover,
.hero-section .scroll-down.social a:hover,
.hero-section .scroll-down.scroll-down:hover {
  color: hsl(var(--t-color-one));
}

.hero-section .social.social a:hover:after,
.hero-section .social.scroll-down:hover:after,
.hero-section .scroll-down.social a:hover:after,
.hero-section .scroll-down.scroll-down:hover:after {
  background-color: hsl(var(--t-color-one));
}

html[dir=ltr] .hero-section .social {
  left: 30px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

html[dir=rtl] .hero-section .social {
  right: 30px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

html[dir=ltr] .hero-section .social:before {
  margin-right: 20px;
}

html[dir=rtl] .hero-section .social:before {
  margin-left: 20px;
}

.hero-section .social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

html[dir=ltr] .hero-section .social ul {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

html[dir=rtl] .hero-section .social ul {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

html[dir=ltr] .hero-section .social ul li {
  -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg);
  transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg);
}

html[dir=rtl] .hero-section .social ul li {
  -webkit-transform: rotateX(0deg) rotateY(-180deg) rotateZ(-90deg);
  transform: rotateX(0deg) rotateY(-180deg) rotateZ(-90deg);
}

html[dir=ltr] .hero-section .social ul li:not(:last-of-type) {
  margin-right: 20px;
}

html[dir=rtl] .hero-section .social ul li:not(:last-of-type) {
  margin-left: 20px;
}

.hero-section .social ul li a:focus {
  outline: none;
}

.enable-focus-style .hero-section .social ul li a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

html[dir=ltr] .hero-section .scroll-down {
  right: 30px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

html[dir=rtl] .hero-section .scroll-down {
  left: 30px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.hero-section .scroll-down a {
  text-transform: inherit;
}

.hero-section .scroll-down a:focus {
  outline: none;
}

.enable-focus-style .hero-section .scroll-down a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

html[dir=ltr] .hero-section .scroll-down:after {
  margin-left: 20px;
}

html[dir=rtl] .hero-section .scroll-down:after {
  margin-right: 20px;
}

@media (min-width: 992px) {
  .hero-section {
    position: relative;
    height: calc(100vh - 70px);
  }

  .hero-section.parallax-animation:hover .layer {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@media (max-width: 991px) {
  .hero-section {
    min-height: calc(100vh - 70px);
    display: grid;
    place-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/* 2. Statistics Section Styles */
/*------------------------------*/
.statistics-section .section-content .statistics-items {
  margin-bottom: -30px;
}

.statistics-section .section-content .statistics-items li {
  margin-bottom: 30px;
  visibility: hidden;
}

.statistics-section .section-content .statistics-items li:nth-of-type(1) .icon {
  background-color: hsl(var(--bg-color-reverse));
  color: hsl(var(--bg-color-one));
}

.statistics-section .section-content .statistics-items li:nth-of-type(1) .icon img {
  -webkit-filter: var(--filter-invert-true);
  filter: var(--filter-invert-true);
}

.statistics-section .section-content .statistics-items li:nth-of-type(2) .icon {
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
}

.statistics-section .section-content .statistics-items li:nth-of-type(2) .icon img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.statistics-section .section-content .statistics-items li:nth-of-type(3) .icon {
  background-color: hsl(var(--bg-color-one));
  color: hsl(var(--bg-color-reverse));
}

.statistics-section .section-content .statistics-items li:nth-of-type(3) .icon img {
  -webkit-filter: var(--filter-invert-false);
  filter: var(--filter-invert-false);
}

.statistics-section .section-content .statistics-items li:hover a {
  margin-top: -15px;
}

.statistics-section .section-content .statistics-items li:hover a>.fa {
  color: hsl(var(--t-color-one));
}

.statistics-section .section-content .statistics-items a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  background-color: hsl(var(--bg-color-two));
  padding: 30px;
  border-radius: 0.63rem;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
}

.statistics-section .section-content .statistics-items a .icon {
  height: 64px;
  width: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.statistics-section .section-content .statistics-items a .icon img {
  width: 40px;
}

.statistics-section .section-content .statistics-items a .text {
  width: calc(100% - 64px - 60px);
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.statistics-section .section-content .statistics-items a .text h3 {
  color: hsl(var(--main-color-one));
  font-size: 2rem;
}

.statistics-section .section-content .statistics-items a>.fa {
  font-size: 1.2rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

html[dir=rtl] .statistics-section .section-content .statistics-items a>.fa {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.statistics-section .section-content .statistics-items a:focus {
  outline: none;
}

.enable-focus-style .statistics-section .section-content .statistics-items a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

/* 3. About Section Styles */
/*-------------------------*/
.about-section .section-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: -50px;
}

.about-section .section-content>* {
  margin-bottom: 50px;
}

.about-section .section-content .about-img {
  position: relative;
  visibility: hidden;
}

@media (min-width: 992px) {
  .about-section .section-content .about-img {
    height: 500px;
  }
}

@media (max-width: 991px) {
  .about-section .section-content .about-img {
    height: 400px;
  }
}

.about-section .section-content .about-img .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.about-section .section-content .about-img .layer:first-of-type {
  height: 100%;
  -webkit-mask-image: url("../assets/images/blob-shape.png");
  /*mask-image: url("../assets/images/blob-shape.png");*/
  -webkit-mask-repeat: no-repeat;
  /*mask-repeat: no-repeat;*/
  -webkit-mask-position: center;
  /*mask-position: center;*/
  -webkit-mask-size: contain;
  /*mask-size: contain;*/
  overflow: hidden;
}

.about-section .section-content .about-img .layer:first-of-type:before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  height: 80%;
  width: 80%;
  background: linear-gradient(150deg, hsl(var(--main-color-one)) 0%, hsl(var(--main-color-two)) 100%);
  -webkit-mask-image: url("../assets/images/blob-shape.png");
  /*mask-image: url("../assets/images/blob-shape.png");*/
  -webkit-mask-repeat: no-repeat;
  /*mask-repeat: no-repeat;*/
  -webkit-mask-position: center;
  /*mask-position: center;*/
  -webkit-mask-size: contain;
  /*mask-size: contain;*/
}

html[dir=ltr] .about-section .section-content .about-img .layer:first-of-type:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

html[dir=rtl] .about-section .section-content .about-img .layer:first-of-type:before {
  right: 50%;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.about-section .section-content .about-img .layer:first-of-type img {
  height: 100%;
  -webkit-filter: drop-shadow(2px 4px 6px black);
  filter: drop-shadow(2px 4px 6px black);
}

.about-section .section-content .about-img .layer:last-of-type {
  height: 50%;
  overflow: hidden;
}

.about-section .section-content .about-img .layer:last-of-type img {
  height: 200%;
}

.about-section .section-content .about-img .layer img {
  position: relative;
  z-index: 2;
  display: block;
  max-width: 100%;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.about-section .section-content .about-text p {
  margin: 0;
}

/* 4. Skills Section Styles */
/*--------------------------*/
.skills-section .section-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: -50px;
}

.skills-section .section-content>* {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .skills-section .section-content .skills-text .toggle-switch-btn {
    margin: auto;
  }
}

.skills-section .section-content .skills-items ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -8px -16px;
  -webkit-animation: fadeIn 0.3s ease-out;
  animation: fadeIn 0.3s ease-out;
}

.skills-section .section-content .skills-items li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: hsl(var(--bg-color-two));
  margin: 0 8px 16px;
  border-radius: 0.63rem;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  cursor: help;
  visibility: hidden;
}

.skills-section .section-content .skills-items li .skill-icon img {
  display: block;
  width: 48px;
  margin: auto;
}

.skills-section .section-content .skills-items li:focus {
  outline: none;
}

.enable-focus-style .skills-section .section-content .skills-items li:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

/* 5. Experience Section Styles */
/*------------------------------*/
.experience-section .section-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: -50px;
}

@media (max-width: 991px) {
  .experience-section .section-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.experience-section .section-content>* {
  margin-bottom: 50px;
}

.experience-section .section-content .experience-timeline {
  position: relative;
}

@media (min-width: 992px) {
  html[dir=ltr] .experience-section .section-content .experience-timeline {
    padding-left: 30px;
    padding-right: calc(30px + 10px);
  }

  html[dir=rtl] .experience-section .section-content .experience-timeline {
    padding-right: 30px;
    padding-left: calc(30px + 10px);
  }
}

@media (max-width: 991px) {
  .experience-section .section-content .experience-timeline {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.experience-section .section-content .experience-timeline .timepath {
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(100% - 10px);
  display: grid;
  -ms-flex-line-pack: center;
  align-content: center;
}

html[dir=ltr] .experience-section .section-content .experience-timeline .timepath {
  left: 0;
}

html[dir=rtl] .experience-section .section-content .experience-timeline .timepath {
  right: 0;
}

.experience-section .section-content .experience-timeline .timepath span {
  visibility: hidden;
}

.experience-section .section-content .experience-timeline .timepath .line {
  display: block;
  width: calc(100% - (30px * 2));
  margin: auto;
  border-top: 7px solid hsl(var(--border-color));
}

.experience-section .section-content .experience-timeline .timepath .line:not(:first-of-type) {
  margin-top: -7px;
}

.experience-section .section-content .experience-timeline .timepath .semicircle {
  display: block;
  height: 87px;
  width: 80px;
  margin-top: -7px;
  border-style: solid;
  border-color: hsl(var(--border-color));
}

html[dir=ltr] .experience-section .section-content .experience-timeline .timepath .semicircle:nth-of-type(4n + 2) {
  margin-left: auto;
  border-width: 7px 7px 7px 0;
  border-radius: 0 2em 2em 0;
}

html[dir=rtl] .experience-section .section-content .experience-timeline .timepath .semicircle:nth-of-type(4n + 2) {
  margin-right: auto;
  border-width: 7px 0 7px 7px;
  border-radius: 2em 0 0 2em;
}

html[dir=ltr] .experience-section .section-content .experience-timeline .timepath .semicircle:nth-of-type(4n + 4) {
  margin-right: auto;
  border-width: 7px 0 7px 7px;
  border-radius: 2em 0 0 2em;
}

html[dir=rtl] .experience-section .section-content .experience-timeline .timepath .semicircle:nth-of-type(4n + 4) {
  margin-left: auto;
  border-width: 7px 7px 7px 0;
  border-radius: 0 2em 2em 0;
}

.experience-section .section-content .experience-timeline .timeline-items {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-rows: 80px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-flow: dense;
  counter-reset: items-count;
}

@media (min-width: 992px) {
  html[dir=ltr] .experience-section .section-content .experience-timeline .timeline-items {
    padding-left: 30px;
  }

  html[dir=rtl] .experience-section .section-content .experience-timeline .timeline-items {
    padding-right: 30px;
  }
}

.experience-section .section-content .experience-timeline .timeline-items li {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 30px;
  cursor: help;
  visibility: hidden;
}

.experience-section .section-content .experience-timeline .timeline-items li:nth-of-type(6n + 4) {
  grid-column: 3;
}

.experience-section .section-content .experience-timeline .timeline-items li:nth-of-type(6n + 5) {
  grid-column: 2;
}

.experience-section .section-content .experience-timeline .timeline-items li:nth-of-type(6n + 6) {
  grid-column: 1;
}

@media (min-width: 992px) {
  .experience-section .section-content .experience-timeline .timeline-items li {
    width: 70px;
    background-color: hsl(var(--bg-color-two));
    color: hsl(var(--t-color-one));
    counter-increment: items-count;
    -webkit-filter: var(--drop-shadow);
    filter: var(--drop-shadow);
  }

  html[dir=ltr] .experience-section .section-content .experience-timeline .timeline-items li {
    border-radius: 0 0.63rem 0.63rem 0;
  }

  html[dir=rtl] .experience-section .section-content .experience-timeline .timeline-items li {
    border-radius: 0.63rem 0 0 0.63rem;
  }

  .experience-section .section-content .experience-timeline .timeline-items li:before {
    content: counter(items-count);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    height: calc(30px + 10px);
    width: calc(30px + 10px);
    background-color: hsl(var(--main-color-one));
    color: hsl(var(--light-color));
    font-size: 1rem;
    font-weight: bold;
    border-radius: 50%;
    -webkit-filter: var(--drop-shadow);
    filter: var(--drop-shadow);
  }

  html[dir=ltr] .experience-section .section-content .experience-timeline .timeline-items li:before {
    left: -30px;
  }

  html[dir=rtl] .experience-section .section-content .experience-timeline .timeline-items li:before {
    right: -30px;
  }
}

@media (max-width: 991px) {
  .experience-section .section-content .experience-timeline .timeline-items li {
    background-color: hsl(var(--main-color-one));
    color: hsl(var(--light-color));
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 0.63rem;
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);
  }
}

.experience-section .section-content .experience-timeline .timeline-items li h3 {
  color: inherit;
  font-size: 1rem;
  margin: 0;
}

html[dir=ltr] .experience-section .section-content .experience-timeline .timeline-items li:empty:after {
  content: "?";
}

html[dir=rtl] .experience-section .section-content .experience-timeline .timeline-items li:empty:after {
  content: "؟";
}

.experience-section .section-content .experience-timeline .timeline-items li:hover,
.experience-section .section-content .experience-timeline .timeline-items li:focus-within {
  z-index: 2;
}

.experience-section .section-content .experience-timeline .timeline-items li:focus {
  outline: none;
}

.enable-focus-style .experience-section .section-content .experience-timeline .timeline-items li:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

/* 6. Portfolio Section Styles */
/*-----------------------------*/
.portfolio-section .section-content .portfolio-items.has-works {
  -webkit-column-gap: 25px;
  -moz-column-gap: 25px;
  column-gap: 25px;
}

@media (min-width: 992px) {
  .portfolio-section .section-content .portfolio-items.has-works {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 991px) {
  .portfolio-section .section-content .portfolio-items.has-works {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 576px) {
  .portfolio-section .section-content .portfolio-items.has-works {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.portfolio-section .section-content .portfolio-items.has-works>li {
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .portfolio-section .section-content .portfolio-items.has-works>li:first-of-type {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    overflow: hidden;
  }
}

.portfolio-section .section-content .portfolio-items:not(.has-works) {
  margin-bottom: -50px;
}

.portfolio-section .section-content .portfolio-items:not(.has-works)>li {
  margin-bottom: 50px;
}

.portfolio-section .section-content .portfolio-items .works-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .portfolio-section .section-content .portfolio-items .works-filter {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

html[dir=ltr] .portfolio-section .section-content .portfolio-items .works-filter li:not(:last-of-type) {
  margin-right: 8px;
}

html[dir=rtl] .portfolio-section .section-content .portfolio-items .works-filter li:not(:last-of-type) {
  margin-left: 8px;
}

html[dir=ltr] .portfolio-section .section-content .portfolio-items .works-filter li:not(:last-of-type):after {
  content: "/";
  margin-left: 8px;
}

html[dir=rtl] .portfolio-section .section-content .portfolio-items .works-filter li:not(:last-of-type):after {
  content: "\\";
  margin-right: 8px;
}

.portfolio-section .section-content .portfolio-items .works-filter li button.active,
.portfolio-section .section-content .portfolio-items .works-filter li button:hover {
  color: hsl(var(--main-color-one));
}

.portfolio-section .section-content .portfolio-items .works-filter li button:focus {
  outline: none;
}

.enable-focus-style .portfolio-section .section-content .portfolio-items .works-filter li button:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.portfolio-section .section-content .portfolio-items>li.portfolio-item {
  border-radius: 0.63rem;
  overflow: hidden;
  -webkit-filter: var(--drop-shadow);
  filter: var(--drop-shadow);
  -webkit-animation: zoomIn 0.2s ease-in-out;
  animation: zoomIn 0.2s ease-in-out;
  will-change: transform;
}

.portfolio-section .section-content .portfolio-items>li.portfolio-item a {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.portfolio-section .section-content .portfolio-items>li.portfolio-item a:hover .item-details {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  visibility: visible;
  opacity: 1;
}

.portfolio-section .section-content .portfolio-items>li.portfolio-item a:hover .item-details .title,
.portfolio-section .section-content .portfolio-items>li.portfolio-item a:hover .item-details .date {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.portfolio-section .section-content .portfolio-items>li.portfolio-item a:hover .item-details .title {
  -webkit-transition: 0.2s linear 0.1s;
  transition: 0.2s linear 0.1s;
}

.portfolio-section .section-content .portfolio-items>li.portfolio-item a:hover .item-details .date {
  -webkit-transition: 0.2s linear 0.2s;
  transition: 0.2s linear 0.2s;
}

.portfolio-section .section-content .portfolio-items>li.portfolio-item:focus-within {
  outline: none;
}

.enable-focus-style .portfolio-section .section-content .portfolio-items>li.portfolio-item:focus-within {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

@media (min-width: 992px) {
  .portfolio-section .section-content .portfolio-items>li [class*=text-box] {
    margin-top: 37.5px;
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .portfolio-section .section-content .portfolio-items>li [class*=text-box] {
    position: relative;
    -webkit-column-span: all;
    -moz-column-span: all;
    column-span: all;
    padding-bottom: 37.5px;
  }
}

.portfolio-section .section-content .portfolio-items>li .more {
  display: block;
  width: 100%;
  color: hsl(var(--main-color-one));
  text-align: center;
  font-size: 1.4rem;
  text-decoration: none;
  border-radius: 0.63rem;
  padding: 10px;
  background-color: #272727;
}

.portfolio-section .section-content .portfolio-items>li .more:hover {
  transform: scale(1.04);
}

.portfolio-section .section-content .portfolio-items>li .more:focus {
  outline: none;
}

.enable-focus-style .portfolio-section .section-content .portfolio-items>li .more:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.portfolio-section .section-content .portfolio-items>li .item-img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.portfolio-section .section-content .portfolio-items>li .item-details {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s 0.2s;
  transition: 0.3s 0.2s;
}

.portfolio-section .section-content .portfolio-items>li .item-details .title,
.portfolio-section .section-content .portfolio-items>li .item-details .date {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: hsl(var(--bg-color-one));
  color: hsl(var(--bg-color-reverse));
  padding: 6px 12px;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  opacity: 0;
}

.portfolio-section .section-content .portfolio-items>li .item-details .title {
  max-width: 100%;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transition: 0.2s linear 0.1s;
  transition: 0.2s linear 0.1s;
}

.portfolio-section .section-content .portfolio-items>li .item-details .date {
  font-size: 0.8rem;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

@media (max-width: 991px) {
  .portfolio-section:not(.archive-page) .portfolio-items>li:first-of-type {
    margin-bottom: 0;
  }
}

/* 8. Contact Section Styles */
/*---------------------------*/
.contact-section .section-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* margin-bottom: -50px; */
}

.contact-section .section-content>* {
  margin-bottom: 50px;
}

.contact-section .section-content .contact-text .contact-info {
  margin-top: 50px;
}

.contact-section .section-content .contact-text .contact-info>li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  visibility: hidden;
}

.contact-section .section-content .contact-text .contact-info>li:not(:last-of-type) {
  margin-bottom: 20px;
}

.contact-section .section-content .contact-text .contact-info>li img {
  width: 24px;
  -webkit-filter: var(--filter-invert-false);
  filter: var(--filter-invert-false);
}

html[dir=ltr] .contact-section .section-content .contact-text .contact-info>li img {
  margin-right: 18px;
}

html[dir=rtl] .contact-section .section-content .contact-text .contact-info>li img {
  margin-left: 18px;
}

.contact-section .section-content .contact-text .contact-info>li div strong {
  display: block;
  margin-bottom: 5px;
}

.contact-section .section-content .contact-text .contact-info>li div a:hover {
  text-decoration: underline;
}

.contact-section .section-content .contact-text .contact-info>li div a:focus {
  outline: none;
}

.enable-focus-style .contact-section .section-content .contact-text .contact-info>li div a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.contact-section .section-content .contact-text .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 30px -5px -10px;
}

html[dir=ltr] .contact-section .section-content .contact-text .social {
  padding-left: 42px;
}

html[dir=rtl] .contact-section .section-content .contact-text .social {
  padding-right: 42px;
}

.contact-section .section-content .contact-text .social li {
  visibility: hidden;
}

.contact-section .section-content .contact-text .social li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: hsl(var(--bg-color-reverse));
  color: hsl(var(--bg-color-one));
  margin: 0 5px 10px;
  border-radius: 50%;
}

.contact-section .section-content .contact-text .social li a:hover {
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
}

.contact-section .section-content .contact-text .social li a:focus {
  outline: none;
}

.enable-focus-style .contact-section .section-content .contact-text .social li a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

.contact-section .section-content .contact-form {
  background-color: hsl(var(--bg-color-two));
  padding: 25px;
  margin: auto;
  border-radius: 0.63rem;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  visibility: hidden;
}

@media (min-width: 768px) {
  .contact-section .section-content .contact-form {
    max-width: 450px;
  }
}

html[dir=rtl] .contact-section .section-content .contact-form .phone-number .fa {
  -webkit-transform: rotateY(180deg) translateY(-50%);
  transform: rotateY(180deg) translateY(-50%);
}

.contact-section .section-content .contact-form .submit-btn {
  width: 100%;
}

/* 2. Single Portfolio Page Styles */
/*---------------------------------*/
.portfolio-page .portfolio-items .work-tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: -5px;
}

@media (max-width: 576px) {
  .portfolio-page .portfolio-items .work-tools {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.portfolio-page .portfolio-items .work-tools li {
  margin-bottom: 5px;
}

html[dir=ltr] .portfolio-page .portfolio-items .work-tools li:not(:last-of-type) {
  margin-right: 5px;
}

html[dir=rtl] .portfolio-page .portfolio-items .work-tools li:not(:last-of-type) {
  margin-left: 5px;
}

.portfolio-page .portfolio-items .work-tools li a {
  display: block;
  background-color: hsl(var(--main-color-one));
  color: hsl(var(--light-color));
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 0.63rem;
}

.portfolio-page .portfolio-items .work-tools li a:hover {
  background-color: hsl(var(--bg-color-reverse));
  color: hsl(var(--bg-color-one));
}

.portfolio-page .portfolio-items .work-tools li a:focus {
  outline: none;
}

.enable-focus-style .portfolio-page .portfolio-items .work-tools li a:focus {
  -webkit-box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
  box-shadow: 0 0 0 3px hsla(var(--main-color-two), 70%);
}

/* 3. Not Found Page Styles */
/*--------------------------*/
.not-found-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.not-found-page .not-found-content {
  text-align: center;
  margin: auto;
}

.not-found-page .not-found-content .not-found-img {
  margin-bottom: 50px;
}

.not-found-page .not-found-content .not-found-img img {
  width: 100%;
  max-width: 600px;
}

.not-found-page .not-found-content .not-found-text {
  margin-bottom: 30px;
}

/* Hide cursor */
/*--------------------------*/
/** {*/
/*  cursor: none;*/
/*}*/

.hideframe {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
}

[v-cloak] {
  display: none;
}

.smallFT {
  font-size: 20px;
}

::-webkit-scrollbar {
  background-color: #2c2c2c;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #474747;
  border-radius: 4px;
}